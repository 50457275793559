import { Button } from "components/Button";
import { TextField } from "components/Forms/TextField";
import { useViewModel } from "./viewModel";
import { ChatIntegration } from "models/chatIntegration";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { CopyText } from "components/Forms/CopyText";
import { MultiSelect } from "components/Forms/MultiSelect";

type Props = {
  selectedIntegration: ChatIntegration | undefined;
  onClose: () => void;
  onSave: SaveOrUpdateChatIntegrationFunction;
};

export const BrevoForm = ({ selectedIntegration, onClose, onSave }: Props) => {
  const viewModel = useViewModel(selectedIntegration, onSave);

  if (viewModel.step === 0) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5"
      >
        <TextField
          label="Nome da integração"
          placeholder="Digite o nome da integração"
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Token de acesso"
          placeholder="Digite o token de acesso"
          error={viewModel.errors.brevo?.token?.message}
          register={viewModel.register("brevo.token", {
            required: { value: true, message: "O token é obrigatório" },
          })}
          variant="secondary"
        />
        {selectedIntegration?.id && (
          <CopyText
            text={`${import.meta.env.VITE_API_URL}/v1/webhooks/brevo/${
              selectedIntegration.id
            }`}
            variant="secondary"
            label="URL do Webhook"
          />
        )}
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  return (
    <form
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <MultiSelect
        label="Selecione os vendedores"
        onChange={(value) => viewModel.setValue("brevo.sellers", value)}
        options={viewModel.sellers.map((seller) => ({
          value: seller.id,
          label: seller.name,
        }))}
        selected={viewModel.selectedSellers}
        error={viewModel.errors.brevo?.sellers?.message}
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button
          loading={viewModel.loadingForm}
          theme="highlight"
          className="px-7"
        >
          Salvar
        </Button>
      </div>
    </form>
  );
};
