import { useState } from "react";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import { queryClient } from "services/queryClient";
import {
  createSheetIntegration,
  updateSheetIntegration,
  getSheetIntegrations,
} from "services/sheetIntegrationService";

import { ValidationError } from "utils/validation";

import { SheetIntegrationForm } from "./form/viewModel";
import { SheetIntegration } from "models/integration";
import { useTranslation } from "react-i18next";

const key = "sheetIntegrations";

export type SaveOrUpdateSheetIntegrationFunction = (
  data: SheetIntegrationForm
) => Promise<void>;

export const useViewModel = (clientId: string) => {
  const {
    data: sheetsIntegrations,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery([key, clientId], () => getSheetIntegrations(clientId));

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<SheetIntegration>();
  const [loadingForm, setLoadingForm] = useState(false);

  const { t } = useTranslation();

  const handleSaveOrUpdateSheetsIntegration: SaveOrUpdateSheetIntegrationFunction =
    async (data) => {
      try {
        if (selectedIntegration) {
          toast.info(t("providers.view.chat_integrations.update_loading"));
          await updateSheetIntegration(selectedIntegration._id, {
            ...data,
            provider: clientId,
          });
        } else {
          toast.info(t("providers.view.chat_integrations.creating_loading"));
          await createSheetIntegration({
            ...data,
            provider: clientId,
          });
        }

        queryClient.invalidateQueries([key, clientId]);

        toast(t("providers.view.chat_integrations.success"), {
          type: "success",
        });
        setLoadingForm(false);
        handleCloseIntegrationModal();
      } catch (err) {
        const _err = err as AxiosError;
        if (_err.isAxiosError && _err.response?.data !== undefined) {
          if (_err.response.status === 400) {
            const data = _err.response.data as {
              errors: ValidationError[] | undefined;
            };

            if (data.errors) {
              const fields = data.errors.map((error) => error.key);
              toast(
                t("providers.view.chat_integrations.fields_error", {
                  fields: fields.join(", "),
                }),
                { type: "error" }
              );
            } else {
              toast(t("providers.view.chat_integrations.general_error"), {
                type: "error",
              });
            }
          } else if (_err.response.status === 501) {
            toast(t("providers.view.chat_integrations.auth_error"), {
              type: "error",
            });
          } else {
            toast(t("providers.view.chat_integrations.general_error"), {
              type: "error",
            });
          }
        } else {
          toast(t("providers.view.chat_integrations.general_error"), {
            type: "error",
          });
        }
        setLoadingForm(false);
      }
    };

  function handleAddIntegration() {
    setSelectedIntegration(undefined);
    handleOpenIntegrationModal();
  }

  function handleEditIntegration(integration: SheetIntegration) {
    setSelectedIntegration(integration);
    handleOpenIntegrationModal();
  }

  const handleOpenIntegrationModal = async () => {
    setShowIntegrationModal(true);
  };

  function handleCloseIntegrationModal() {
    setSelectedIntegration(undefined);
    setShowIntegrationModal(false);
  }

  return {
    t,
    sheetsIntegrations: sheetsIntegrations?.data,
    refetch: refetch,
    showIntegrationModal,
    selectedIntegration,
    isLoading: isLoading || (isFetching && !sheetsIntegrations),
    isError,
    loadingForm,
    handleAddIntegration,
    handleEditIntegration,
    handleCloseIntegrationModal,
    handleSaveOrUpdateSheetsIntegration,
  };
};
