import { useState } from "react";
import { useTranslation } from "react-i18next";

export function AuthCheckbox() {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(true);

  function handleToggleCheck() {
    setIsChecked(!isChecked);
  }

  return (
    <div
      className="flex gap-1.5 text-white text-xs cursor-pointer items-center"
      onClick={handleToggleCheck}
    >
      <img
        src={`/icons/form/checkbox_${isChecked ? "checked" : "unchecked"}.svg`}
        alt="Manter logado"
      />
      <span className="">{t("login.keep_me_logged_in")}</span>
    </div>
  );
}
