import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { ChatIntegration, ChatIntegrationSellerRelated, ChatIntegrationType } from "models/chatIntegration";
import { AxiosError } from "axios";
import { messageToError, ValidationError } from "utils/validation";
import { toast } from "react-toastify";
import { getDepartments, getIntegrationSellers, webhookCheck } from "services/chatIntegrationService";
import { Department } from "models/department";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { Channel } from "models/channel";
import { queryClient } from "services/queryClient";
import { IntegrationSeller } from "models/integrationSeller";
import { Seller } from "models/seller";
import { getSellers } from "services/sellerService";

type SZChatIntegrationFormData = {
  departmentId: string;
  email: string;
  password: string;
  token: string;
  host: string;
  channels: { id: string }[];
  sellers: string[];
  sellersRelated: ChatIntegrationSellerRelated[];
  webhookVerified?: boolean;
};

type SZChatIntegrationForm = {
  type: ChatIntegrationType;
  name: string;
  szChat: SZChatIntegrationFormData;
};

export type SZChatIntegrationAPIForm = Omit<SZChatIntegrationForm, "szChat"> & {
  szChat: Omit<SZChatIntegrationFormData, "channels"> & {
    channels: string[];
  };
};

export const useViewModel = (
  provider: string,
  selectedIntegration: ChatIntegration | undefined,
  handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    setError,
    watch,
    control,
  } = useForm<SZChatIntegrationForm>({
    defaultValues: {
      szChat: {
        channels: [],
      },
    },
  });
  const { append, remove } = useFieldArray({
    name: "szChat.channels",
    control,
  });

  const [step, setStep] = useState(0);
  const [loadingForm, setLoadingForm] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [sellers, setSelers] = useState<IntegrationSeller[]>([]);
  const [internalSellers, setInternalSellers] = useState<Seller[]>([]);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const departmentId = watch("szChat.departmentId") ?? "";
  const channelsList = watch("szChat.channels") ?? [];
  const selectedSellers = watch("szChat.sellers") ?? [];
  const selectedSellersRelated = watch("szChat.sellersRelated") ?? [];

  const selectedSellersOptions = useMemo(() => {
    return selectedSellers.map(
      (seller) => sellers.find((_seller) => _seller.name === seller)!
    );
  }, [sellers, selectedSellers]);

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" },
    });
  }, [register]);

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "");
    setValue("type", selectedIntegration?.type ?? "szChat");
    setValue("szChat.host", selectedIntegration?.szChat?.host ?? "");
    setValue(
      "szChat.departmentId",
      selectedIntegration?.szChat?.departmentId ?? ""
    );
    setValue("szChat.sellers", selectedIntegration?.szChat?.sellers ?? []);
    setValue(
      "szChat.sellersRelated",
      selectedIntegration?.szChat?.sellersRelated ?? []
    );
    setValue("szChat.token", selectedIntegration?.szChat?.token ?? "");
    setValue("szChat.email", selectedIntegration?.szChat?.email ?? "");
    setValue("szChat.password", selectedIntegration?.szChat?.password ?? "");
    setValue(
      "szChat.channels",
      selectedIntegration?.szChat?.channels?.map((channel) => ({
        id: channel,
      })) ?? []
    );
  }, [selectedIntegration, setValue]);

  async function handleSubmitFormFirstStep(data: SZChatIntegrationForm) {
    if (loadingForm) {
      return;
    }
    setLoadingForm(true);

    try {
      toast.info("Buscando os departamentos...");
      const { departments, channels } = await getDepartments({
        host: data.szChat.host,
        token: data.szChat.token,
        email: data.szChat.email,
        password: data.szChat.password,
        type: "szChat",
      });
      const { attendents } = await getIntegrationSellers({
        type: data.type as ChatIntegrationType,
        szChat: {
          host: data.szChat.host,
          email: data.szChat.email,
          password: data.szChat.password,
          token: data.szChat.token,
        },
      });

      setDepartments(departments);
      setSelers(attendents);
      setChannels(channels ?? []);
      setLoadingForm(false);
      setStep(1);

      toast.success("Departamentos buscados com sucesso!");
    } catch (error) {
      toast.error(errorMessage(error as Error));
      setLoadingForm(false);
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError;
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as {
        errors: ValidationError[] | undefined;
      };

      if (data.errors) {
        const fields = data.errors.map((error) => {
          setError(error.key as keyof SZChatIntegrationForm, {
            message: messageToError(error.message),
          });
          return error.key;
        });
        return `Os campos ${fields.join(", ")} são inválidos`;
      }
      if (_err.response.status === 400)
        return "O domínio ou o token são inválidos";
    }
    return "Ocorreu um erro, por favor tente novamente";
  };

  async function handleSubmitFormSecondStep() {
    if (loadingForm) return;
    setLoadingForm(true);

    const internalSellers = await getSellers(1, 100, provider);
    setInternalSellers(internalSellers.data);

    setLoadingForm(false);

    setStep(2);
  }

  async function handleSubmitFormThirdStep(data: SZChatIntegrationForm) {
    if (loadingForm) {
      return;
    }
    setLoadingForm(true);
    await handleSaveOrUpdate({
      ...data,
      szChat: {
        ...data.szChat,
        channels: data.szChat.channels.map((channel) => channel.id),
      },
    });
    setLoadingForm(false);
  }

  const onToggleChannel = (channelId: string) => () => {
    const isChecked = channelsList.some((channel) => channel.id === channelId);
    if (!isChecked) {
      append({ id: channelId });
    } else {
      const channelIndex = channelsList.findIndex(
        (channel) => channel.id === channelId
      );
      remove(channelIndex);
    }
  };

  const handleShowWebhookStep = () => {
    setStep(3);
  };

  const handleCancelWebhookStep = () => {
    setStep(0);
  };

  const handleVerifyWebhook = async () => {
    if (!selectedIntegration) return;

    setVerifyLoading(true);

    let refetch = false;

    try {
      const isVerified = await webhookCheck(selectedIntegration.id);
      selectedIntegration.szChat!.webhookVerified = isVerified;
      if (isVerified) {
        toast.success("Webhook verificado com sucesso!");
        refetch = true;
      } else {
        toast.warn("O webhook não está verificado");
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao verificar o webhook");
    }

    setVerifyLoading(false);

    if (refetch) {
      await queryClient.invalidateQueries("chatIntegrations");
    }
  };

  return {
    selectedIntegration,
    selectedSellersOptions,
    selectedSellersRelated,
    internalSellers,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    handleSubmitFormThirdStep,
    step,
    departments,
    departmentId,
    channels,
    channelsList,
    sellers,
    selectedSellers,
    onToggleChannel,
    handleShowWebhookStep,
    handleCancelWebhookStep,
    handleVerifyWebhook,
    verifyLoading,
  };
};
