import useAuth from "hooks/useAuth";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useViewmodel = () => {
  const { user, selectedProvider } = useAuth();
  const { t } = useTranslation();

  const [showConfigModal, setShowConfigModal] = useState(false);

  const handleShowConfigModal = () => {
    setShowConfigModal(true);
  };

  const handleCloseConfigModal = () => {
    setShowConfigModal(false);
  };

  return {
    user,
    t,
    selectedProvider,
    showConfigModal,
    handleShowConfigModal,
    handleCloseConfigModal,
  };
};
