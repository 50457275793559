import { AuthInput } from "components/Auth/AuthInput";

import { useViewModel } from "./viewModel";

import { Link } from "react-router-dom";
import { Button } from "components/Button";

export default function ConfirmRecover() {
  const {
    register,
    errors,
    handleConfirmRecover,
    handleSubmit,
    password,
    isLoading,
    t,
  } = useViewModel();

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="mix-blend-multiply bg-primary py-10 rounded-3xl w-[18.5rem] h-[25rem]"></div>
      <div className="absolute px-12 py-10 rounded-3xl text-center">
        <img src="/images/brand/logo.png" alt="Login logo" />
        <form
          onSubmit={handleSubmit(handleConfirmRecover)}
          className="flex flex-col gap-4 mt-7 mb-4"
        >
          <AuthInput
            label={t("login.password")}
            placeholder={t("login.password_placeholder")}
            error={errors.password?.message}
            type="password"
            leftIcon="/icons/auth/pass.svg"
            register={register("password", {
              required: { value: true, message: t("login.password_required") },
              minLength: { value: 6, message: t("login.password_invalid") },
            })}
          />
          <AuthInput
            label={t("confirm_recover_password.password_confirm")}
            placeholder={t(
              "confirm_recover_password.password_confirm_placeholder"
            )}
            error={errors.passwordConfirm?.message}
            type="password"
            leftIcon="/icons/auth/pass.svg"
            register={register("passwordConfirm", {
              required: { value: true, message: t("login.password_required") },
              minLength: { value: 6, message: t("login.password_invalid") },
              validate: (value) =>
                value === password ||
                t("confirm_recover_password.password_confirm_invalid"),
            })}
          />
          <Button
            type="submit"
            size="big"
            theme="highlight"
            loading={isLoading}
            className="mt-4"
          >
            {t("actions.recover")}
          </Button>
        </form>
        <Link to="/login" className="text-white text-xs">
          {t("recover_password.back_to_login")}
        </Link>
      </div>
    </div>
  );
}
