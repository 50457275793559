import { PageHeader } from "components/Page/PageHeader";
import { useTranslation } from "react-i18next";

export function Page404() {
  const { t } = useTranslation();

  return (
    <div>
      <PageHeader title={t("404.title")} />
    </div>
  );
}
