import { useViewModel } from "./viewModel";

import { LeadsBoard } from "./components";

export const LeadsSalesFunnel = () => {
  const { t, selectedProvider } = useViewModel();

  if (selectedProvider.id === "admin") {
    return (
      <div className="flex flex-col px-8 tablet:px-18 h-[calc(70svh)] items-center justify-center">
        <p className="text-xl text-center">{t("leads.select")}</p>
      </div>
    );
  }

  return <LeadsBoard />;
};
