import axios, { AxiosError } from "axios";

import { TokenCookie, RefreshTokenCookie } from "cookies/authCookie";
import { SelectedProvider } from "store/slices/auth";

type FailedRequestQueueItem = {
  onSuccess: (newToken: string) => void;
  onFailure: () => void;
};

let failedRequestsQueue: FailedRequestQueueItem[] = [];
let isRefreshing = false;

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export const externalApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export const botApi = axios.create({
  baseURL: import.meta.env.VITE_BOT_URL,
});

api.interceptors.request.use(async (config) => {
  const headers = config.headers ?? {};

  const provider: SelectedProvider | null = sessionStorage.getItem(
    "selected_provider"
  )
    ? JSON.parse(sessionStorage.getItem("selected_provider")!)
    : null;

  const token = TokenCookie.get();
  if (token) {
    headers["Authorization"] = `${token}`;
  }

  if (provider?.id) {
    headers["provider-id"] = provider.id;
    headers["ProviderId"] = provider.id;
  }

  config.headers = headers;
  return config;
});

type RefreshTokenResponse = {
  token: string;
  refreshToken: string;
};

function postRefreshToken(refreshToken: string) {
  return api.post<RefreshTokenResponse>(`/auth/refresh`, {
    refreshToken,
  });
}

type AuthError = {
  code: string;
};

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      const refreshToken = RefreshTokenCookie.get();
      const response = error.response.data as AuthError | undefined;

      if (response?.code === "token.expired" && refreshToken) {
        const originalRequestConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;
          postRefreshToken(refreshToken)
            .then(({ data }) => {
              TokenCookie.set(data.token);
              RefreshTokenCookie.set(data.refreshToken);

              failedRequestsQueue.forEach((request) =>
                request.onSuccess(data.token)
              );
            })
            .catch(() => {
              failedRequestsQueue.forEach((request) => request.onFailure());
            })
            .finally(() => {
              failedRequestsQueue = [];
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (newToken: string) => {
              originalRequestConfig.headers![
                "Authorization"
              ] = `Bearer ${newToken}`;

              resolve(api(originalRequestConfig));
            },
            onFailure: () => {
              reject(error);
            },
          });
        });
      } else {
        TokenCookie.remove();
        RefreshTokenCookie.remove();
        window.location.href = "/";
        return;
      }
    }
    return Promise.reject(error);
  }
);

export { api };
