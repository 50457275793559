import { useBreakpoints } from "hooks/useBreakpoints";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { diffInMonths, diffInMonthsFromToday } from "utils/date";

type DateItem = {
  date: Date;
  isInCurrentMonth: boolean;
};

const maxMonthsDiffInPast = 24;
const maxMonthsDiffInFuture = 1;

export const useViewModel = (
  targetDate: Date,
  handleSelectDateFrame: (date: Date) => void
) => {
  const [dateItems, setDateItems] = useState<DateItem[][]>([]);
  const { isMobile } = useBreakpoints();
  const { t, i18n } = useTranslation();

  const dateAreInLimit = useMemo(() => {
    const diffInMonthsFromTargetDate = diffInMonthsFromToday(targetDate);
    return {
      minLimit: diffInMonthsFromTargetDate >= maxMonthsDiffInPast,
      maxLimit: diffInMonthsFromTargetDate <= -maxMonthsDiffInFuture + 1,
    };
  }, [targetDate]);

  const months = useMemo(
    () =>
      t("filters.months", { returnObjects: true }).map((month, index) => ({
        label: month,
        value: index,
      })),
    [targetDate, i18n.language]
  );
  const years = useMemo(() => {
    const years = [
      {
        label: new Date().getFullYear().toString(),
        value: new Date().getFullYear(),
      },
      {
        label: (new Date().getFullYear() - 1).toString(),
        value: new Date().getFullYear() - 1,
      },
    ];

    const firstDayOfNextYear = new Date(new Date().getFullYear() + 1, 0, 1);
    const monthsRemainingInFuture =
      maxMonthsDiffInFuture -
      Math.abs(diffInMonthsFromToday(firstDayOfNextYear)) +
      1;

    const lastDayOfPastYearLimit = new Date(
      new Date().getFullYear() - 2,
      11,
      31
    );
    const monthsRemainingInPast =
      maxMonthsDiffInPast -
      Math.abs(diffInMonthsFromToday(lastDayOfPastYearLimit));

    if (monthsRemainingInFuture > 0) {
      years.unshift({
        label: (new Date().getFullYear() + 1).toString(),
        value: new Date().getFullYear() + 1,
      });
    }

    if (monthsRemainingInPast > 0) {
      years.push({
        label: (new Date().getFullYear() - 2).toString(),
        value: new Date().getFullYear() - 2,
      });
    }

    return years;
  }, []);

  useEffect(() => {
    const initOfMonth = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );
    const endOfMonth = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth() + 1,
      1,
      0,
      0,
      0,
      0
    );
    const diffInDays = Math.round(
      (endOfMonth.getTime() - initOfMonth.getTime()) / (1000 * 60 * 60 * 24)
    );

    const dates: DateItem[][] = [];
    let index = -1;

    for (let i = 0; i < diffInDays; i++) {
      const date = new Date(initOfMonth.getTime() + i * 24 * 60 * 60 * 1000);

      if ((date.getDay() === 1 && i > 0) || i === 0) {
        index++;
        dates.push([]);
      }

      if (i === 0) {
        let diff = date.getDay() === 0 ? 7 : date.getDay();
        while (diff > 1) {
          const adjustDate = new Date(date);
          adjustDate.setDate(date.getDate() - diff + 1);
          dates[index].push({ date: adjustDate, isInCurrentMonth: false });
          diff--;
        }
      }
      dates[index].push({
        date: date,
        isInCurrentMonth: true,
      });
      if (i === diffInDays - 1) {
        let diff = 7 - date.getDay();
        let counter = 1;
        while (diff > 0 && date.getDay() > 0) {
          const adjustDate = new Date(date);
          adjustDate.setDate(date.getDate() + counter);
          dates[index].push({ date: adjustDate, isInCurrentMonth: false });
          diff--;
          counter++;
          if (adjustDate.getDay() === 1 || i === 0) {
            index++;
            dates.push([]);
          }
        }
      }
    }
    setDateItems(dates);
  }, [targetDate]);

  function handleAfterSelectDateFrame(date: Date) {
    if (date < new Date()) {
      const dateLimitInPast = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - maxMonthsDiffInPast,
        1,
        0,
        0
      );
      const diff = diffInMonths(date, dateLimitInPast);
      if (diff > 0) {
        const adjustedDate = isMobile
          ? dateLimitInPast
          : new Date(
              dateLimitInPast.getFullYear(),
              dateLimitInPast.getMonth() + 1,
              1,
              0,
              0
            );
        handleSelectDateFrame(adjustedDate);
      } else {
        handleSelectDateFrame(date);
      }
    } else if (date > new Date()) {
      const dateLimitInFuture = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + maxMonthsDiffInFuture,
        1,
        0,
        0
      );
      const diff = diffInMonths(date, dateLimitInFuture);

      if (diff < 1) {
        const adjustedDate = new Date(
          dateLimitInFuture.getFullYear(),
          dateLimitInFuture.getMonth() - 1,
          1,
          0,
          0
        );
        handleSelectDateFrame(adjustedDate);
      } else if (diff === 1) {
        const adjustedDate = isMobile
          ? date
          : new Date(
              dateLimitInFuture.getFullYear(),
              dateLimitInFuture.getMonth() - 1,
              1,
              0,
              0
            );
        handleSelectDateFrame(adjustedDate);
      } else {
        handleSelectDateFrame(date);
      }
    }
  }

  function handleChangeMonth(month: number) {
    const newDate = new Date(targetDate.getFullYear(), month, 1, 0, 0, 0, 0);
    handleAfterSelectDateFrame(newDate);
  }

  function handleChangeYear(year: number) {
    const newDate = new Date(year, targetDate.getMonth(), 1, 0, 0, 0, 0);
    handleAfterSelectDateFrame(newDate);
  }

  return {
    t,
    dateItems,
    dateAreInLimit,
    months,
    years,
    handleChangeMonth,
    handleChangeYear,
  };
};
