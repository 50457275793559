import { DotLoading } from "components/DotLoading";
import { SearchBox } from "components/SearchBox";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { searchLeadByPhone } from "services/leadService";
import { LeadCardItem } from "../LeadsSalesFunnel/components/LeadCardItem";
import { cardColumnColor } from "models/board";
import { Lead, leadToStoreLeadCard } from "models/lead";
import { useDispatch } from "react-redux";
import { updateSelectedLead } from "store/slices/boardFilters";
import { Masks, unMaskText } from "utils/mask";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
};

export const LeadSearchBox = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { selectedProvider } = useAuth();
  const [search, setSearch] = useState("");
  const [intendSearch, setIntendSearch] = useState("");
  const searchData = useQuery({
    queryKey: ["search-leads", search, selectedProvider.id],
    queryFn: () => searchLeadByPhone(unMaskText(search), 1),
    enabled: search.length > 2,
    cacheTime: 0,
  });
  searchData.isLoading =
    searchData.isLoading || (searchData.isFetching && !searchData.data);
  const { data: leads } = searchData.data ?? {};
  const { t } = useTranslation();

  const handleChangeSearch = (search: string) => {
    setIntendSearch(search);
  };

  const handleOpenLead = (lead: Lead) => () => {
    dispatch(updateSelectedLead(leadToStoreLeadCard(lead)));
    onClose();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearch(intendSearch);
    }, 700);
    return () => clearTimeout(timeout);
  }, [intendSearch]);

  return (
    <SearchBox
      value={intendSearch}
      onChange={handleChangeSearch}
      onClose={onClose}
      mask={Masks.PHONE}
    >
      {searchData.isLoading && (
        <div className="flex w-full justify-center">
          <DotLoading />
        </div>
      )}
      {!searchData.isLoading && !searchData.error && leads?.length === 0 && (
        <p className="text-center text-sm text-white/50">
          {t("leads.search.empty")}
        </p>
      )}
      {!searchData.isLoading &&
        leads &&
        leads?.length > 0 &&
        leads.map((lead) => (
          <div
            key={lead.id}
            onClick={handleOpenLead(lead)}
            className="cursor-pointer"
          >
            <LeadCardItem
              {...lead}
              color={
                cardColumnColor[
                  lead.provider.leadsStatus.find(
                    (status) => status.id === lead.status
                  )?.tag?.value ?? "unkown"
                ]
              }
              provider={lead.provider._id}
              statusLabel={
                lead.provider.leadsStatus.find(
                  (status) => status.id === lead.status
                )?.name
              }
            />
          </div>
        ))}
    </SearchBox>
  );
};
