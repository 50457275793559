import { Pagination } from "components/Tables/Pagination";
import { Table } from "components/Tables/Table";

import { useViewModel } from "./viewModel";
import { PageHeader } from "components/Page/PageHeader";
import { PageFilter } from "components/Page/PageFilter";
import { Button } from "components/Button";
import { CreateReportForm } from "./new";

export default function Reports() {
  const viewModel = useViewModel();

  if (!viewModel.hasProvider) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <PageHeader title={viewModel.t("reports.title")} />
          <div className="flex items-center gap-3">
            <PageFilter hideTimeFilter />
          </div>
        </div>
        <div className="w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]">
          <p className="text-white text-xl text-center">
            {viewModel.t("reports.select")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {viewModel.showReportDialog && (
        <CreateReportForm onClose={viewModel.handleHideReportDialog} />
      )}
      <div className="flex justify-between items-center">
        <PageHeader title={viewModel.t("reports.title")} />
        <div className="flex items-center gap-3">
          <PageFilter hideTimeFilter />
          <Button
            leftIcon="/icons/form/add.svg"
            onClick={viewModel.handleShowReportDialog}
          >
            {viewModel.t("actions.new")}
          </Button>
        </div>
      </div>
      <Table
        className="mt-7"
        headers={viewModel.t("reports.table.headers", { returnObjects: true })}
        fields={[
          { key: "type" },
          { key: "status" },
          { key: "dateRange" },
          { key: "createdAtFormatted" },
        ]}
        transforms={[
          {
            key: "type",
            transform: (report) =>
              viewModel.t(`reports.table.fields.name.${report.type}`),
          },
          {
            key: "status",
            transform: (report) =>
              viewModel.t(`reports.table.fields.status.${report.status}`),
          },
        ]}
        data={viewModel.reports}
        isLoading={viewModel.isLoading}
        hasError={viewModel.isError}
        onRetry={viewModel.retry}
        isRetching={viewModel.isRefetching}
        actions={(report) => {
          if (report.status !== "success") return null;

          return (
            <>
              <a href={report.downloadUrl}>{viewModel.t("actions.download")}</a>
            </>
          );
        }}
      />
      <Pagination
        currentPage={viewModel.page}
        handleChangePage={viewModel.setPage}
        numberOfPages={viewModel.nbPages ?? 0}
      />
    </div>
  );
}
