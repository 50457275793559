import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuthUser } from "models/authUser";
import { Seller } from "models/seller";

export type SelectedProvider = {
  id: string;
  name: string;
  currency: string;
  averageTicket: number;
  goal: number;
};

//State
export interface IAuthState {
  user?: IAuthUser;
  isLoading: boolean;
  selectedProvider: SelectedProvider;
  sellers?: Seller[];
  selectedSeller: string;
}

const INITIAL_STATE: IAuthState = {
  user: undefined,
  isLoading: true,
  selectedProvider: sessionStorage.getItem("selected_provider")
    ? JSON.parse(sessionStorage.getItem("selected_provider")!)
    : { id: "admin", name: "Administador", currency: "BRL", averageTicket: 0, goal: 0 },
  sellers: undefined,
  selectedSeller: "all",
};

//Slice
const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    onLogin: (state: IAuthState, action: PayloadAction<IAuthUser>) => {
      state.user = action.payload;
    },
    onLogout: (state: IAuthState) => {
      state.user = undefined;
    },
    setIsLoadingUser: (state: IAuthState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedProvider: (
      state: IAuthState,
      action: PayloadAction<SelectedProvider>
    ) => {
      state.selectedProvider = action.payload;
      if (action.payload.id === "admin") {
        sessionStorage.removeItem("selected_provider");
      } else {
        sessionStorage.setItem(
          "selected_provider",
          JSON.stringify(action.payload)
        );
      }
    },
    updateSellers: (
      state: IAuthState,
      action: PayloadAction<Seller[] | undefined>
    ) => {
      state.sellers = action.payload;
    },
    updateSelectedSeller: (
      state: IAuthState,
      action: PayloadAction<string>
    ) => {
      state.selectedSeller = action.payload;
    },
  },
});

export const {
  onLogin,
  onLogout,
  setIsLoadingUser,
  setSelectedProvider,
  updateSellers,
  updateSelectedSeller,
} = authSlice.actions;
export default authSlice.reducer;
