import {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { BoardColumn, outOfAreaTags, sellTags } from "models/board";
import { LeadCard } from "models/lead";
import { useState } from "react";
import { moveCard } from "./utils";
import { boardCardMoveAPI } from "services/leadService";
import { toast } from "react-toastify";
import useAuth from "hooks/useAuth";
import { Log } from "utils/log";
import { useTranslation } from "react-i18next";

type LeadCardMove = {
  index: number;
  item: LeadCard;
  columnIndex: number;
};

type LeadCardMoveIntercept = {
  leardCardMove: LeadCardMove;
  movedTo: {
    columnIndex: number;
    index: number;
    newPosition: number;
  };
};

type Props = {
  leadsColumns: BoardColumn<LeadCard>[];
  setLeadsColumns: React.Dispatch<
    React.SetStateAction<BoardColumn<LeadCard>[]>
  >;
  refetch: () => void;
};

const parseConcreteDate = (date: string | Date) => {
  if (typeof date === "string") return new Date(date);
  return date;
};

const parseUndefinedDate = (date: string | Date | undefined) => {
  if (date === undefined) return undefined;
  if (typeof date === "string") return date;
  return date.toISOString();
};

const parseDate = (date: string | Date | null | undefined) => {
  if (date === undefined) return undefined;
  if (date === null) return null;
  if (typeof date === "string") return date;
  return date.toISOString();
};

export const useBoardDragDropViewModel = ({
  leadsColumns,
  setLeadsColumns,
  refetch,
}: Props) => {
  const [activeItem, setActiveItem] = useState<LeadCardMove | null>(null);
  const [leadSold, setLeadSold] = useState<LeadCardMoveIntercept | null>(null);
  const [leadOutOfArea, setLeadOutOfArea] =
    useState<LeadCardMoveIntercept | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 0.1 } })
  );

  const { user } = useAuth();
  const { t } = useTranslation();

  const copyLeadCard = (lead: LeadCard): LeadCard => ({
    ...lead,
    date: parseConcreteDate(lead.date),
    movedAt: parseUndefinedDate(lead.movedAt),
    selledAt: parseDate(lead.selledAt),
  });

  const copyColumns = (
    columns?: BoardColumn<LeadCard>[]
  ): BoardColumn<LeadCard>[] => {
    const _columns = JSON.parse(
      JSON.stringify(columns ?? leadsColumns)
    ) as BoardColumn<LeadCard>[];

    return _columns.map((column) => {
      return {
        ...column,
        items: column.items.map(copyLeadCard),
      };
    });
  };

  const findColumnIndexByColumnOrCardId = (id: string | undefined) => {
    if (id) {
      const columns = copyColumns();
      const index = columns.findIndexOrUndefined((column) => column.id === id);

      if (index) return index;

      return columns.findIndexOrUndefined((column) => {
        return column.items.some((lead) => lead.id === id);
      });
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const activeId = active.id;
    const activeContainer = findColumnIndexByColumnOrCardId(
      activeId.toString()
    );

    if (activeContainer === undefined) {
      return;
    }

    const columns = copyColumns();

    const item =
      columns[activeContainer].items.find((lead) => lead.id === activeId) ||
      null;
    const index = columns[activeContainer].items.findIndex(
      (lead) => lead.id === activeId
    );

    if (!item) {
      return;
    }

    setActiveItem({
      index: index,
      columnIndex: activeContainer,
      item: copyLeadCard(item),
    });
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    const activeId = active.id;
    const overId = over?.id;
    const activeContainer = findColumnIndexByColumnOrCardId(
      activeId.toString()
    );
    const overContainer = findColumnIndexByColumnOrCardId(overId?.toString());

    if (
      activeContainer === undefined ||
      overContainer === undefined ||
      activeContainer === overContainer ||
      !overId
    ) {
      return;
    }

    setLeadsColumns((columns) => {
      const _columns = copyColumns(columns);

      const activeItems = _columns[activeContainer];
      const overItems = _columns[overContainer];

      const activeIndex = activeItems.items.findIndex(
        (item) => item.id === activeId
      );
      const overIndex = overItems.items.findIndex((item) => item.id === overId);

      let newIndex;

      if (overId in _columns) {
        newIndex = overItems.items.length + 1;
      } else {
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top > over.rect.top + over.rect.height;

        const modifier = isBelowOverItem ? 0 : 1;
        newIndex =
          overIndex >= 0 ? overIndex + modifier : overItems.items.length + 1;
      }

      const updateColumns = [..._columns];

      updateColumns[activeContainer] = {
        ..._columns[activeContainer],
        items: activeItems.items.filter((lead) => lead.id !== activeId),
      };

      activeItems.items[activeIndex] = {
        ...activeItems.items[activeIndex],
        status: overItems.id,
      };

      updateColumns[overContainer] = {
        ..._columns[overContainer],
        items: [
          ...overItems.items.slice(0, newIndex),
          activeItems.items[activeIndex],
          ...overItems.items.slice(newIndex),
        ],
      };

      if (activeContainer !== overContainer) {
        updateColumns[activeContainer].nbItems -= 1;
        updateColumns[overContainer].nbItems += 1;
      }

      return [...updateColumns];
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const activeId = active.id;
    const overId = over?.id;

    const activeContainer = findColumnIndexByColumnOrCardId(
      activeId.toString()
    );
    const overContainer = findColumnIndexByColumnOrCardId(overId?.toString());

    if (
      activeContainer === undefined ||
      overContainer === undefined ||
      !overId ||
      !activeItem
    ) {
      return;
    }

    const columns = copyColumns();

    const activeIndex = columns[activeContainer].items.findIndex(
      (item) => item.id === activeId
    );
    const overIndex = columns[overContainer].items.findIndex(
      (item) => item.id === overId
    );

    let hasValidMove = false;

    let updatedColumns: BoardColumn<LeadCard>[] = [...columns];

    if (activeIndex !== overIndex) {
      updatedColumns = [...columns];
      updatedColumns[overContainer] = {
        ...columns[overContainer],
        items: arrayMove(columns[overContainer].items, activeIndex, overIndex),
      };

      setLeadsColumns([...updatedColumns]);

      if (
        activeItem.columnIndex !== overContainer ||
        activeItem.index !== overIndex
      ) {
        hasValidMove = true;
      }
    } else if (activeItem.columnIndex !== overContainer) {
      hasValidMove = true;
    }

    if (hasValidMove) {
      updateInternalPosition(
        activeItem,
        overContainer,
        overIndex,
        updatedColumns
      );
    }

    setActiveItem(null);
  };

  const updateInternalPosition = (
    activeItem: LeadCardMove,
    overContainer: number,
    overIndex: number,
    updatedColumns: BoardColumn<LeadCard>[]
  ) => {
    // Wait next render
    setTimeout(() => {
      const columns = copyColumns(updatedColumns);
      // console.log(`Moved card ${activeItem.item.name} from ${activeItem.columnIndex}:${activeItem.index} to ${overContainer}:${overIndex}`)

      const leads = columns[overContainer].items;
      const prevLead = leads[overIndex - 1];
      const nextLead = leads[overIndex + 1];

      const newPosition = moveCard(prevLead?.position, nextLead?.position);

      const newColumn = [...columns];
      newColumn[overContainer] = {
        ...columns[overContainer],
        items: leads.map((lead, index) => {
          if (index === overIndex) {
            return {
              ...lead,
              status: columns[overContainer].id,
              position: newPosition,
            };
          }
          return lead;
        }),
      };

      setLeadsColumns(newColumn);

      // The lead is moved to a sold column without a selled at date
      if (
        sellTags.includes(columns[overContainer].tag?.value ?? "") &&
        !activeItem.item.selledAt
      ) {
        setLeadSold({
          leardCardMove: activeItem,
          movedTo: {
            columnIndex: overContainer,
            index: overIndex,
            newPosition,
          },
        });

        return;
      }

      // The lead is moved to a out of area column without a description
      if (
        outOfAreaTags.includes(columns[overContainer].tag?.value ?? "") &&
        activeItem.item.description === ""
      ) {
        setLeadOutOfArea({
          leardCardMove: activeItem,
          movedTo: {
            columnIndex: overContainer,
            index: overIndex,
            newPosition,
          },
        });
        return;
      }

      handleUpdateCardMoveOnAPI(
        activeItem.item,
        columns[overContainer].id,
        newPosition
      );
    });
  };

  const rollbackSoldLead = () => {
    if (leadSold) {
      rollbackMove(leadSold);
    }

    setLeadSold(null);
  };

  const rollbackOutOfAreaLead = () => {
    if (leadOutOfArea) {
      rollbackMove(leadOutOfArea);
    }

    setLeadOutOfArea(null);
  };

  const rollbackMove = (leadMove: LeadCardMoveIntercept) => {
    const { leardCardMove, movedTo } = leadMove;
    const { columnIndex, index, item } = leardCardMove;

    const columns = copyColumns();

    const newColumn = [...columns];
    newColumn[columnIndex] = {
      ...columns[columnIndex],
      nbItems: columns[columnIndex].nbItems + 1,
      items: [
        ...columns[columnIndex].items.slice(0, index),
        item,
        ...columns[columnIndex].items.slice(index),
      ],
    };
    newColumn[movedTo.columnIndex] = {
      ...columns[movedTo.columnIndex],
      nbItems: columns[movedTo.columnIndex].nbItems - 1,
      items: columns[movedTo.columnIndex].items.filter(
        (lead) => lead.id !== item.id
      ),
    };

    setLeadsColumns(newColumn);
  };

  const confirmMoveSoldLead = async (updatedLead: LeadCard) => {
    if (!leadSold) return;

    confirmMove(updatedLead, leadSold);

    setLeadSold(null);
  };

  const confirmMoveOutOfAreaLead = async (updatedLead: LeadCard) => {
    if (!leadOutOfArea) return;

    confirmMove(updatedLead, leadOutOfArea);

    setLeadOutOfArea(null);
  };

  const confirmMove = async (
    updatedLead: LeadCard,
    leadMove: LeadCardMoveIntercept
  ) => {
    const { leardCardMove, movedTo } = leadMove;

    const columns = copyColumns();

    //update selledAt date
    const updatedLeads = [...columns];
    updatedLeads[movedTo.columnIndex] = {
      ...columns[movedTo.columnIndex],
      items: columns[movedTo.columnIndex].items.map((lead, index) => {
        if (index === movedTo.index) {
          return {
            ...lead,
            description: updatedLead.description,
            selledAt: updatedLead.selledAt,
          };
        }
        return lead;
      }),
    };

    setLeadsColumns(updatedLeads);

    handleUpdateCardMoveOnAPI(
      leardCardMove.item,
      columns[movedTo.columnIndex].id,
      movedTo.newPosition
    );
  };

  const handleUpdateCardMoveOnAPI = async (
    card: LeadCard,
    columnId: string,
    newPosition: number
  ) => {
    if (card.seller === null && user?.role === "seller") {
      card.seller = {
        ...user,
        id: user._id,
        providerName: user.provider?.name!,
        status: 0,
        providerId: user.provider?.id!,
      };

      const columns = copyColumns();

      const newColumn = [...columns];
      const cardTarget = newColumn
        .find((column) => column.id === columnId)
        ?.items.find((lead) => lead.id === card.id);

      if (cardTarget) {
        cardTarget.seller = card.seller;
      }

      setLeadsColumns(newColumn);
    }

    boardCardMoveAPI(card.id, columnId, newPosition).catch((error) => {
      Log.error("Error on move card", error);
      toast.error(t("leads.board.errors.failed_to_move", { name: card.name }));
      refetch();
    });
  };

  return {
    activeItem,
    sensors,
    isDraging: activeItem !== null,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    confirmMoveSoldLead,
    confirmMoveOutOfAreaLead,
    leadSold,
    leadOutOfArea,
    rollbackSoldLead,
    rollbackOutOfAreaLead,
  };
};
