import { InputHTMLAttributes, useEffect } from "react";

import { useInputType } from "hooks/useInputType";

import { Label } from "../Label";
import { cn } from "utils/styles";
import { Masks, maskText } from "utils/mask";

type SearchFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  id?: string
  label?: string;
  error?: string;
  helpText?: string;
  required?: boolean
  containerClassName?: string
  variant?: "primary" | "secondary"
  leftIcon?: string
  mask?: Masks | Masks[]
  value?: string
  onChange?: (value: string) => void
}

export function SearchField({
  label, type = "text", placeholder, variant = 'primary', error, helpText, mask, value,
  className, disabled, required, id, containerClassName, leftIcon, onChange, ...props
}: SearchFieldProps) {

  const { inputType, inputMode } = useInputType(type)

  function handleMask(event : React.ChangeEvent<HTMLInputElement>) {
    let text = event.target.value

    if (mask === Masks.PHONE && text.startsWith('55') && (text.length === 13 || text.length === 12)) {
      text = text.slice(2)
    }

    if (mask) {
      onChange?.(maskText(text, mask, value?.length ?? 0))
    } else {
      onChange?.(text)
    }
  }

  useEffect(() => {
    if (mask && value !== undefined) {
      onChange?.(maskText(value ?? "", mask, value?.length ?? 0))
    }
  }, [])

  return (
    <div className={cn(containerClassName, "flex flex-col w-full")}>
      { label && <Label className="px-5 mb-3">{label} {required ? "*" : ""}</Label> }
      <div className={cn('flex rounded-full', variant === 'primary' ? 'bg-primaryMultiply' : 'bg-white/30')}>
        {leftIcon && (
          <img
            className="cursor-pointer ml-5"
            src={leftIcon} 
            width="24"
            height="24"
            alt="Alterar visibilidade da senha"
          />
        )}
        <input
          id={id}
          disabled={disabled}
          className={cn(className, "py-2 pl-2 pr-5 outline-none border-transparent bg-transparent w-full text-base placeholder:text-white/35")}
          type={inputType}
          inputMode={inputMode}
          placeholder={placeholder}
          value={value}
          onChange={handleMask}
          {...props}
        />
      </div>
      {(error || helpText) && (
        <p className={cn(error ? 'text-red-500' : 'text-black', 'text-xs py-2 leading-4 px-5')}>
          {error ?? helpText}
        </p>
      )}
    </div>
  );
}