import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import useAuth from "hooks/useAuth";
import { getReports } from "services/reporService";
import { toMs } from "utils/date";
import { useTranslation } from "react-i18next";

export const REPORT_CLIENTS_QUERY_KEY = "reports";

export const useViewModel = () => {
  const { selectedProvider, user } = useAuth();
  const [showReportDialog, setShowReportDialog] = useState(false);
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isError, refetch, isRefetching } = useQuery(
    [REPORT_CLIENTS_QUERY_KEY, currentPage, selectedProvider.id],
    () => getReports(currentPage, 20, selectedProvider.id),
    {
      enabled: user !== undefined && selectedProvider.id !== "admin",
    }
  );

  useEffect(() => {
    if (data?.data.some((report) => report.status === "pending")) {
      const interval = setInterval(() => {
        refetch();
      }, toMs({ seconds: 2 }));

      return () => clearInterval(interval);
    }
  }, [data]);

  const handleShowReportDialog = () => {
    setShowReportDialog(true);
  };

  const handleGenerateReport = () => {
    setShowReportDialog(false);
  };

  const handleHideReportDialog = () => {
    setShowReportDialog(false);
  };

  return {
    t,
    isRefetching,
    reports: data?.data,
    page: currentPage,
    nbPages: data?.pagination.nbPages,
    isLoading: isLoading,
    isError: isError,
    retry: refetch,
    setPage: setCurrentPage,
    user,
    showReportDialog,
    handleShowReportDialog,
    handleGenerateReport,
    handleHideReportDialog,
    hasProvider: selectedProvider.id !== "admin",
  };
};
