import useAuth from "hooks/useAuth";
import { useFilters } from "hooks/useFilters";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateSelectedSeller } from "store/slices/auth";
import { updateSelectedDate, updateSelectedPeriod } from "store/slices/filters";
import { dateRangeFromPeriod } from "utils/date";
import { DATE_PERIODS, DatePeriodItem, DatePeriods } from "utils/filters";

export const useViewModel = () => {
  const { user, selectedProvider, sellers, selectedSeller } = useAuth();
  const { selectedPeriod } = useFilters();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  function handleChangePeriods(value: string | undefined) {
    dispatch(updateSelectedPeriod(value as DatePeriods));
    handleCalculateDateFromPeriod(value as DatePeriods);
  }

  const sellerOptions = useMemo(() => {
    const options = sellers?.map((seller) => ({
      label: seller.name,
      value: seller.id,
    }));

    if (options) {
      options.push({
        label: t("filters.sellers.unassigned"),
        value: "unassigned",
      });
      options.unshift({ label: t("filters.sellers.all"), value: "all" });
    }
    return options;
  }, [sellers, i18n.language]);

  const datePeriodOptions: DatePeriodItem[] = useMemo(() => {
    const periods: DatePeriodItem[] = DATE_PERIODS.map((period) => ({
      label: t(`filters.periods.${period}`),
      value: period,
    }));

    if (selectedPeriod === "custom") {
      periods.push({ label: t("filters.periods.custom"), value: "custom" });
    }

    return periods;
  }, [selectedPeriod, i18n.language]);

  const handleCalculateDateFromPeriod = (period: DatePeriods) => {
    const newDateRange = dateRangeFromPeriod(period);

    if (newDateRange) {
      dispatch(updateSelectedDate(JSON.stringify(newDateRange)));
    }
  };

  const handleChangeSeller = (value: string | undefined) => {
    if (value) {
      dispatch(updateSelectedSeller(value));
    }
  };

  return {
    t,
    selectedPeriod,
    handleChangePeriods,
    datePeriodOptions,
    sellerOptions,
    selectedProvider,
    selectedSeller,
    handleChangeSeller,
    isSeller: user?.role === "seller",
  };
};
