import { Button } from "components/Button";
import { TextField } from "components/Forms/TextField";
import { useViewModel } from "./viewModel";
import { ChatIntegration } from "models/chatIntegration";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { MultiSelect } from "components/Forms/MultiSelect";

type Props = {
  selectedIntegration: ChatIntegration | undefined;
  onClose: () => void;
  onSave: SaveOrUpdateChatIntegrationFunction;
};

export const MyClientsForm = ({
  selectedIntegration,
  onClose,
  onSave,
}: Props) => {
  const viewModel = useViewModel(selectedIntegration, onSave);

  if (viewModel.step === 0) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5"
      >
        <TextField
          label="Nome da integração"
          placeholder="Digite o nome da integração"
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Email"
          placeholder="Digite o email de acesso no Meus Clientes"
          error={viewModel.errors.myClients?.email?.message}
          register={viewModel.register("myClients.email", {
            required: { value: true, message: "O email é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Senha"
          placeholder="Digite a senha de acesso no Meus Clientes"
          error={viewModel.errors.myClients?.password?.message}
          register={viewModel.register("myClients.password", {
            required: { value: true, message: "A senha é obrigatório" },
          })}
          variant="secondary"
        />
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  return (
    <form
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <MultiSelect
        label="Selecione as filas"
        onChange={(value) => viewModel.setValue("myClients.queues", value)}
        options={viewModel.queues.map((queue) => ({
          value: queue._id,
          label: queue.name,
        }))}
        selected={viewModel.selectedQueues}
        error={viewModel.errors.myClients?.queues?.message}
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button
          loading={viewModel.loadingForm}
          theme="highlight"
          className="px-7"
        >
          Salvar
        </Button>
      </div>
    </form>
  );
};
