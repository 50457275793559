import { Link, useNavigate, useParams } from "react-router-dom";

import { getSeller, updateSeller } from "services/sellerService";
import { FormStates } from "components/Forms/FormStates";
import { SellerForm } from "../form";
import { FormFields } from "../form/viewModel";
import { toast } from "react-toastify";
import { queryClient } from "services/queryClient";
import { SELLER_CLIENTS_QUERY_KEY } from "../viewModel";
import { PageHeader } from "components/Page/PageHeader";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { PageFilter } from "components/Page/PageFilter";
import { Button } from "components/Button";

type QueryParams = {
  id: string;
};

export function EditSeller() {
  const { id } = useParams<QueryParams>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch } = useQuery(
    [SELLER_CLIENTS_QUERY_KEY, "form", id],
    () => getSeller(id!),
    { enabled: !!id }
  );

  async function handleSubmit(fields: FormFields) {
    toast(t("sellers.edit.update_loading"), { type: "info" });
    await updateSeller({ ...fields, id: id! });
    queryClient.invalidateQueries(SELLER_CLIENTS_QUERY_KEY);
    toast(t("sellers.edit.update_success"), { type: "success" });
    navigate(`/vendedores/${id!}`);
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <PageHeader title={t("sellers.edit.title")} backPath="/vendedores" />
        <div className="flex items-center gap-3">
          <PageFilter hideAllFilters />
          <Link to={`/vendedores/${id!}`}>
            <Button>{t("actions.view")}</Button>
          </Link>
        </div>
      </div>
      <FormStates isLoading={isLoading} hasError={isError} onRetry={refetch} />
      {data && (
        <SellerForm
          enabled
          seller={data}
          onSubmit={handleSubmit}
          action={t("actions.edit")}
        />
      )}
    </div>
  );
}
