import { cn } from "utils/styles";
import { InvestedCard } from "./InvestedCard";
import { LeadsCard } from "./LeadsCard";
import { LeadsCost } from "./LeadsCost";
import { type DashboardData  } from "services/dashboardService";
import { Resume1Card } from "../SalesResume/Resume1Card";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
};

export const LeadsResume = ({
  variant = "primary",
  data
}: Props) => {

  return (
    <div
      className={cn(
        "grid grid-cols-1 laptop:grid-cols-2 desktop-lg:grid-cols-[repeat(3,1fr),auto] gap-3 rounded-2xl",
        variant === "primary" ? "p-4 bg-primary/40" : "flex-1"
      )}
    >
      <InvestedCard
        variant={variant}
        data={data}
      />
      <LeadsCard
        variant={variant}
        data={data}
      />
      <LeadsCost
        variant={variant}
        data={data}
      />
      <Resume1Card
        variant={variant}
        data={data}
      />
    </div>
  );
};
