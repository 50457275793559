import { Button } from "components/Button";
import { Link } from "react-router-dom";

export const TermsOfUse = () => {
  return (
    <div className="px-8 py-8">
      <div>
        <h1 className="text-2xl font-bold">Termos de Uso - Dedir</h1>
      </div>
      <div>
        <div className="space-y-4">
          <h2 className="text-sm font-bold">Última atualização: 07/11/2024</h2>
          <p>
            Estes Termos de Uso regem o uso da aplicação Dedir. Ao acessar,
            navegar ou utilizar nossos serviços, você concorda com os termos
            aqui estabelecidos. Portanto, leia atentamente este documento e
            entre em contato caso tenha qualquer dúvida.
          </p>
          Copy <h2 className="text-xl font-bold">Uso da Aplicação</h2>
          <p>
            A aplicação Dedir é fornecida "como está" e pode ser utilizada para
            os fins propostos. Você concorda em utilizá-la de forma responsável,
            respeitando as leis e regulamentos aplicáveis.
          </p>
          <p>
            Você é o único responsável por suas ações e conteúdo dentro da
            aplicação. Não é permitido:
          </p>
          <ul className="list-disc pl-6">
            <li>Violar direitos de propriedade intelectual de terceiros.</li>
            <li>
              Enviar conteúdo ilegal, prejudicial, ameaçador, abusivo ou
              difamatório.
            </li>
            <li>Interferir no funcionamento normal da aplicação.</li>
            <li>
              Tentar acessar ou modificar informações de outros usuários sem
              autorização.
            </li>
          </ul>
          <h2 className="text-xl font-bold">Propriedade Intelectual</h2>
          <p>
            Todos os direitos de propriedade intelectual relacionados à
            aplicação Dedir, incluindo, mas não se limitando a, software,
            marcas, logotipos e conteúdo, pertencem a nós ou a nossos
            licenciadores. Você não adquire nenhum direito de propriedade sobre
            esses itens.
          </p>
          <h2 className="text-xl font-bold">Limitação de Responsabilidade</h2>
          <p>
            Não nos responsabilizamos por quaisquer danos, diretos ou indiretos,
            decorrentes do uso da aplicação Dedir, incluindo, mas não se
            limitando a, lucros cessantes, perda de dados ou danos à reputação.
          </p>
          <p>
            Não garantimos que a aplicação estará sempre disponível, livre de
            erros ou atenderá às suas expectativas.
          </p>
          <h2 className="text-xl font-bold">Término e Suspensão</h2>
          <p>
            Podemos suspender ou encerrar seu acesso à aplicação a qualquer
            momento, por qualquer motivo, incluindo, mas não se limitando a,
            violação destes Termos de Uso.
          </p>
          <h2 className="text-xl font-bold">Alterações nestes Termos</h2>
          <p>
            Podemos atualizar estes Termos de Uso periodicamente. Caso haja
            alterações significativas, iremos notificá-lo. A data da última
            atualização estará sempre disponível no topo deste documento.
          </p>
          <h2 className="text-xl font-bold">Contato</h2>
          <p>
            Se você tiver qualquer dúvida, sugestão ou preocupação sobre nossos
            Termos de Uso, entre em contato conosco pelo e-mail dev@delipe.com.br
          </p>
        </div>
      </div>
      <Link to="/">
        <Button className="mt-4.5">Ir para admin</Button>
      </Link>
    </div>
  );
};
