import { cn } from "utils/styles";

type IntegrationRowProps = {
  name: string;
  path: string;
  active?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};

export const IntegrationRow = ({
  children,
  path,
  active = true,
  ...integration
}: IntegrationRowProps) => {
  return (
    <div className="bg-primaryMultiply rounded-full flex py-3 pl-8 pr-4 items-center">
      <div className="flex items-center flex-1 gap-2.5">
        <img
          src={path}
          className={cn("max-h-6 max-w-6", !active && "grayscale")}
        />
        <p
          className={cn(
            "flex-1 text-xl line-clamp-1",
            !active && "line-through"
          )}
        >
          {integration.name}
        </p>
      </div>
      <div className="flex gap-5">{children}</div>
    </div>
  );
};
