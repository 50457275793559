import { Button } from "components/Button";
import { IntegrationContainer } from "../components/integration-container";
import { useViewModel } from "./viewModel";
import { IntegrationRow } from "../components/integration-row";
import { ChatIntegrationsForm } from "./form";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Link } from "react-router-dom";

type ChatsIntegrationProps = {
  clientId: string;
};

export const ChatsIntegration = ({ clientId }: ChatsIntegrationProps) => {
  const {
    chatIntegrations,
    refetch,
    showIntegrationModal,
    selectedIntegration,
    isLoading,
    isError,
    handleAddIntegration,
    handleEditIntegration,
    handleCloseIntegrationModal,
    handleSaveOrUpdateChatIntegration,
    handleDeleteIntegration,
    handleCloseDeleteModal,
    isDeleting,
    showDeleteModal,
    initialIntegrationType,
    handleOpenDeleteIntegrationModal,
    t,
  } = useViewModel(clientId);

  return (
    <>
      {showIntegrationModal && (
        <ChatIntegrationsForm
          selectedIntegrationObject={selectedIntegration}
          onClose={handleCloseIntegrationModal}
          onSaveOrUpdate={handleSaveOrUpdateChatIntegration}
          providerId={clientId}
          initialIntegration={initialIntegrationType}
        />
      )}
      {showDeleteModal && selectedIntegration && (
        <ConfirmDialog
          title={t("providers.view.chat_integrations.delete")}
          message={t("providers.view.chat_integrations.delete_confirm", {
            name: selectedIntegration.name,
          })}
          show
          onConfirm={handleDeleteIntegration}
          onCancel={handleCloseDeleteModal}
          isLoading={isDeleting}
        />
      )}
      <IntegrationContainer
        action={{ label: t("actions.add"), onClick: handleAddIntegration }}
        hasData={(chatIntegrations?.length ?? 0) > 0}
        isError={isError}
        isLoading={isLoading}
        onRetry={refetch}
        title={t("providers.view.chat_integrations.title")}
        className="mt-12"
      >
        {chatIntegrations?.map((integration) => (
          <IntegrationRow
            key={integration.id}
            path={`/icons/chat-bots/${integration.type}.png`}
            {...integration}
          >
            {integration.type === "whatsappUnofficial" && (
              <Link
                to={`/conectar?id=${integration.id}&client=${integration.provider}`}
              >
                <Button size="tiny" className="min-w-[5rem]">
                  {t("actions.connect")}
                </Button>
              </Link>
            )}
            {integration.type === "huggy" &&
            !integration.huggy?.token ? (
              <Button
                onClick={() => handleEditIntegration(integration)}
                size="tiny"
                className="min-w-[5rem]"
              >
                {t("actions.conclude")}
              </Button>
            ) : (
              <Button
                onClick={() => handleEditIntegration(integration)}
                size="tiny"
                className="w-10"
              >
                <img
                  src="/icons/form/edit.svg"
                  alt={t("actions.edit")}
                  className="h-5"
                />
              </Button>
            )}
            <Button
              onClick={() => handleOpenDeleteIntegrationModal(integration)}
              size="tiny"
              className="w-10"
            >
              <img
                src="/icons/form/delete.svg"
                alt={t("actions.remove")}
                className="h-5"
              />
            </Button>
          </IntegrationRow>
        ))}
      </IntegrationContainer>
    </>
  );
};
