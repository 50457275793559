import { Button } from "components/Button";
import { DateFilterCalendar } from "../DateFilterCalendar";
import { useViewModel } from "./viewModel";
import { Dialog } from "components/Dialog";

export const DatePickerModal = () => {
  const {
    dateFrame,
    handleChangeDateFrame,
    selectedDate,
    handleSelectDateFrame,
    selectedDateRange,
    handleSelectManualDate,
    handleConfirmChanges,
    showDatePicker,
    handleToggleDatePicker,
    t,
  } = useViewModel();

  return (
    <div>
      <button
        className="font-input py-2 px-5 bg-white/30 rounded-full flex gap-3 w-full items-center justify-center"
        onClick={handleToggleDatePicker}
      >
        <img src="icons/form/calendar.svg" alt="" />
        <span>{selectedDateRange}</span>
      </button>
      {showDatePicker && (
        <Dialog onClose={handleToggleDatePicker} className="px-8 pt-8 pb-4">
          <div>
            <div className="flex mb-2.5 gap-6">
              <DateFilterCalendar
                calendar="left"
                targetDate={
                  new Date(dateFrame.getFullYear(), dateFrame.getMonth(), 1, 0)
                }
                onChangeFrame={handleChangeDateFrame}
                selectedDate={selectedDate}
                handleSelectManualDate={handleSelectManualDate}
                handleSelectDateFrame={handleSelectDateFrame}
              />
              <DateFilterCalendar
                calendar="right"
                targetDate={
                  new Date(
                    dateFrame.getFullYear(),
                    dateFrame.getMonth() + 1,
                    1,
                    0
                  )
                }
                onChangeFrame={handleChangeDateFrame}
                selectedDate={selectedDate}
                handleSelectManualDate={handleSelectManualDate}
                handleSelectDateFrame={handleSelectDateFrame}
              />
            </div>
            <div className="flex justify-end gap-4">
              <Button
                size="small"
                theme="cancel"
                onClick={handleToggleDatePicker}
              >
                {t("actions.cancel")}
              </Button>
              <Button
                size="small"
                theme="highlight"
                onClick={handleConfirmChanges}
              >
                {t("actions.update")}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
