import { useState } from "react";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postRecover } from "services/authService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type LoginFormInputs = {
  email: string;
};

export const useViewModel = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginFormInputs>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  async function handleRecoverPassword({ email }: LoginFormInputs) {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await postRecover(email);
      toast.success(t("recover_password.success"));
      navigate("/login");
    } catch (err) {
      const _err = err as AxiosError;
      if (_err.response?.status === 400) {
        setError("email", { message: t("recover_password.email_not_found") });
      } else {
        setError("email", { message: t("recover_password.general_error") });
      }
    }
    setIsLoading(false);
  }

  return {
    t,
    register,
    errors,
    handleSubmit,
    handleRecoverPassword,
    isLoading,
  };
};
