import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { DashboardData } from "services/dashboardService";
import { getCurrencySymbol, toCurrency, toPercent } from "utils/format";
import { cn } from "utils/styles";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
};

export const ConvertionCard = ({ variant = "primary", data }: Props) => {
  const { t, i18n } = useTranslation();
  const { selectedProvider } = useAuth();

  return (
    <div
      className={cn(
        "rounded-2xl flex flex-col items-center justify-center gap-2  min-h-[7rem] laptop:flex-1",
        variant === "primary"
          ? "bg-primaryMultiply/70 px-8 desktop:px-4"
          : "border border-white/30 py-2 px-2 desktop-lg:px-4"
      )}
    >
      <div className="flex items-center gap-2 text-sm">
        <p className={cn("text-secondary", variant === "primary" ? "" : "text-xs")}>
          {t("dashboard.cards.conversionRate")}
        </p>
        <div className="font-input">
          <p
            className={cn(
              "font-bold text-center",
              variant === "primary" ? "text-2xl" : "text-xl"
            )}
          >
            {toPercent(data.byComputed.sells.convertionRate, i18n.language)}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2 text-sm">
        <p className={cn("text-secondary", variant === "primary" ? "" : "text-xs")}>
          {t("dashboard.cards.sellsCost")}
        </p>
        <div className="font-input">
          <p
            className={cn(
              "font-bold text-center",
              variant === "primary" ? "text-2xl" : "text-xl"
            )}
          >
            <span className="text-sm">
              {getCurrencySymbol(selectedProvider.currency, i18n.language)}
            </span>
            {toCurrency(data.byComputed.sells.sellCost, i18n.language, selectedProvider.currency)}
          </p>
        </div>
      </div>
    </div>
  );
};
