import { useViewModel } from "./viewModel";
import { Select } from "components/Forms/Select";
import { DatePickerModal } from "./DatePickerModal";

export function DateFilter() {
  const {
    selectedPeriod,
    handleChangePeriods,
    datePeriodOptions,
    selectedProvider,
    sellerOptions,
    selectedSeller,
    handleChangeSeller,
    isSeller,
    t,
  } = useViewModel();

  return (
    <div className="flex flex-col gap-4">
      {selectedProvider.id !== "admin" && !isSeller && (
        <>
          <p>{t("filters.seller_select")}:</p>
          <Select
            options={sellerOptions ?? []}
            onChange={handleChangeSeller}
            selected={selectedSeller}
            variant="secondary"
            placeholder={t("filters.seller_select")}
            centered
            isLoading={sellerOptions === undefined}
          />
        </>
      )}
      <p>{t("filters.period_select")}</p>
      <Select
        options={datePeriodOptions}
        onChange={handleChangePeriods}
        selected={selectedPeriod}
        variant="secondary"
        centered
      />
      <DatePickerModal />
    </div>
  );
}
