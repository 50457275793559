import { cn } from "utils/styles";
import { ConvertionCard } from "./ConvertionCard";
import { SalesCard } from "./SalesCard";
import { type DashboardData  } from "services/dashboardService";
import { RoiCard } from "./RoiCard";
import { GoalCard } from "./GoalCard";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
  showConfigModal: () => void;
};

export const SalesResume = ({
  variant = "primary",
  data,
  showConfigModal
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-col desktop:flex-row gap-3 desktop:gap-1.5 rounded-2xl",
        variant === "primary" ? "bg-primary/40 p-4" : "flex-1"
      )}
    >
      <SalesCard
        variant={variant}
        data={data}
      />
      <GoalCard
        variant={variant}
        data={data}
        showConfigModal={showConfigModal}
      />
      <ConvertionCard
        variant={variant}
        data={data}
      />
      <RoiCard
        variant={variant}
        data={data}
      />
    </div>
  );
};
