import { Button } from "components/Button";
import { IntegrationContainer } from "../components/integration-container";
import { useViewModel } from "./viewModel";
import { IntegrationRow } from "../components/integration-row";
import { CampaignsIntegrationsForm } from "./form";
import { ConfirmDialog } from "components/ConfirmDialog";

type CampaignsIntegrationProps = {
  clientId: string;
};

export const CampaignsIntegration = ({
  clientId,
}: CampaignsIntegrationProps) => {
  const {
    response,
    showIntegrationModal,
    handleAddIntegration,
    handleCloseIntegrationModal,
    refetch,
    handleEditIntegration,
    selectedIntegration,
    isLoading,
    isError,
    handleSubmitForm,
    handleCloseDeleteModal,
    handleConfirmDelete,
    handleDeleteIntegration,
    showDeleteModal,
    isDeleting,
    t,
  } = useViewModel(clientId);

  return (
    <>
      {showIntegrationModal && (
        <CampaignsIntegrationsForm
          selectedIntegration={selectedIntegration}
          onClose={handleCloseIntegrationModal}
          onSaveOrUpdate={handleSubmitForm}
          initialIntegrationType="facebook"
        />
      )}
      {showDeleteModal && selectedIntegration && (
        <ConfirmDialog
          title={t("providers.view.integrations.remove_title")}
          message={t("providers.view.integrations.remove_message", {
            name: selectedIntegration.name,
          })}
          show
          onConfirm={handleConfirmDelete}
          onCancel={handleCloseDeleteModal}
          isLoading={isDeleting}
        />
      )}
      <IntegrationContainer
        action={{
          label: t("actions.add"),
          onClick: handleAddIntegration,
        }}
        hasData={(response?.data?.length ?? 0) > 0}
        isError={isError}
        isLoading={isLoading}
        onRetry={refetch}
        title={t("providers.view.integrations.title")}
        className="mt-12"
      >
        {response?.data.map((integration) => (
          <IntegrationRow
            key={integration._id}
            path={`/icons/campaigns/${integration.type}.svg`}
            {...integration}
          >
            <Button
              onClick={() => handleEditIntegration(integration)}
              size="tiny"
              className="w-10"
            >
              <img
                src="/icons/form/edit.svg"
                alt={t("actions.edit")}
                className="h-5"
              />
            </Button>
            <Button
              onClick={() => handleDeleteIntegration(integration)}
              size="tiny"
              className="w-10"
            >
              <img
                src="/icons/form/delete.svg"
                alt={t("actions.remove")}
                className="h-5"
              />
            </Button>
          </IntegrationRow>
        ))}
      </IntegrationContainer>
    </>
  );
};
