import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import { ConfirmDialogProps } from "components/ConfirmDialog";
import { statusToText } from "models/provider";
import { changeProviderStatus, getProviders } from "services/providerService";
import { queryClient } from "services/queryClient";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "hooks/useQueryParams";

export const PROVIDER_CLIENTS_QUERY_KEY = "providers";

const filterOptions = [
  { value: "-", label: "Todos" },
  { value: "0", label: "Ativos" },
  { value: "1", label: "Pausados" },
  { value: "2", label: "Inativos" },
]

type QueryParams = {
  page?: string;
  filter?: string;
};

export const useViewModel = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { page, filter } = useQueryParams<QueryParams>();

  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [numberOfPages, setNumberOfPages] = useState<number | undefined>(
    undefined
  );
  const [selectedFilter, setSelectedFilter] = useState<string>(filter ??"0");

  const { data, isLoading, isError, refetch } = useQuery(
    [PROVIDER_CLIENTS_QUERY_KEY, currentPage, selectedFilter],
    () => getProviders(currentPage, 20, selectedFilter)
  );

  const handleChangeFilter = (value: string | undefined) => {
    if (!value) {
      setSelectedFilter("-")
    } else {
      setSelectedFilter(value);
    }
    setCurrentPage(1);
  }

  useEffect(() => {
    if (data?.pagination.nbPages) {
      setNumberOfPages(data.pagination.nbPages);
    }
  }, [data?.pagination.nbPages]);

  const [confirmDialogData, setConfirmDialogData] =
    useState<ConfirmDialogProps>();

  async function handleChangeStatus(index: number, newStatus: number) {
    setConfirmDialogData({
      show: true,
      title: t("providers.update_status_title"),
      message: t("providers.update_status_message", {
        status: statusToText(newStatus, t),
      }),
      onConfirm: async () => {
        setConfirmDialogData(undefined);
        const loadingToast = toast(t("providers.update_status_loading"), {
          type: "info",
          autoClose: false,
        });
        await changeProviderStatus(data!.data![index].id, newStatus);
        toast.dismiss(loadingToast);
        toast(t("providers.update_status_success"), { type: "success" });
        queryClient.invalidateQueries([
          PROVIDER_CLIENTS_QUERY_KEY
        ]);
      },
      onCancel: () => {
        setConfirmDialogData(undefined);
      },
    });
  }

  const handleResetPassword = (email: string) => {
    setSelectedUserEmail(email);
    setShowResetPasswordDialog(true);
  }

  const handleCloseResetPasswordDialog = () => {
    setShowResetPasswordDialog(false);
    setSelectedUserEmail(undefined);
  }

  return {
    t,
    providers: data?.data,
    currentPage: currentPage,
    numberOfPages: numberOfPages,
    isLoading: isLoading,
    isError: isError,
    retry: refetch,
    confirmDialogData: confirmDialogData,
    setCurrentPage: setCurrentPage,
    handleChangeStatus: handleChangeStatus,
    navigate: navigate,
    handleChangeFilter,
    selectedFilter,
    filterOptions,
    showResetPasswordDialog,
    handleResetPassword,
    selectedUserEmail,
    handleCloseResetPasswordDialog,
  };
};
