import { useViewmodel } from "./viewModel";
import { DotLoading } from "components/DotLoading";
import { Button } from "components/Button";
import { cn } from "utils/styles";
import { LeadsResume } from "./components/LeadsResume";
import { SalesResume } from "./components/SalesResume";
import { PlatformRow } from "./components/PlatformRow";

interface Props {
  showConfigModal: () => void;
}

export const Dashboard = ({ showConfigModal }: Props) => {
  const {
    hasProvider,
    dashboardData,
    isLoading,
    hasError,
    refetch,
    tab,
    setTab,
    t,
  } = useViewmodel();

  if (!hasProvider) {
    return (
      <div className="w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]">
        <p className="text-white text-xl text-center">
          {t("dashboard.no_provider")}
        </p>
      </div>
    );
  }

  if (hasError)
    return (
      <div className="w-full flex flex-col gap-6 items-center justify-center text-center h-[calc(75svh)]">
        <p className="text-accent-500 text-xl">{t("dashboard.error_title")}</p>
        <p>{t("dashboard.error_message")}</p>
        <Button onClick={refetch}>{t("actions.retry")}</Button>
      </div>
    );

  if (isLoading || !dashboardData)
    return (
      <div className="w-full flex flex-col gap-6 items-center justify-center h-[calc(75svh)]">
        <p className="text-white text-xl">{t("dashboard.loading")}</p>
        <DotLoading />
      </div>
    );

  return (
    <div>
      <div className="flex justify-around text-center text-xl my-3">
        <div />
        <button
          onClick={() => setTab("leads")}
          className={cn(
            "w-32 py-1.5 rounded-full",
            tab === "leads" ? "bg-primary/40" : "bg-primaryMultiply"
          )}
        >
          Leads
        </button>
        <button
          onClick={() => setTab("sales")}
          className={cn(
            "w-32 py-1.5 rounded-full",
            tab === "sales" ? "bg-primary/40" : "bg-primaryMultiply "
          )}
        >
          Vendas
        </button>
        <div />
      </div>
      {tab === "leads" ? (
        <LeadsResume
          data={dashboardData.total}
        />
      ) : (
        <SalesResume
          data={dashboardData.total}
          showConfigModal={showConfigModal}
        />
      )}
      {tab === "leads" ? (
        <div className="mt-8 flex flex-col gap-4">
          <PlatformRow plataform="meta_ads" className="">
            <LeadsResume
              variant="secondary"
              data={dashboardData.facebook}
            />
          </PlatformRow>
          <PlatformRow plataform="google_ads" className="">
            <LeadsResume
              variant="secondary"
              data={dashboardData.google}
            />
          </PlatformRow>
          <PlatformRow plataform="organic" className="">
            <LeadsResume
              variant="secondary"
              data={dashboardData.organic}
            />
          </PlatformRow>
        </div>
      ) : (
        <div className="mt-8 flex flex-col gap-4">
          <PlatformRow plataform="meta_ads" className="gap-8 desktop:gap-0">
            <SalesResume
              variant="secondary"
              data={dashboardData.facebook}
              showConfigModal={showConfigModal}
            />
          </PlatformRow>
          <PlatformRow plataform="google_ads" className="gap-8 desktop:gap-0">
            <SalesResume
              variant="secondary"
              data={dashboardData.google}
              showConfigModal={showConfigModal}
            />
          </PlatformRow>
          <PlatformRow plataform="organic" className="gap-8 desktop:gap-0">
            <SalesResume
              variant="secondary"
              data={dashboardData.organic}
              showConfigModal={showConfigModal}
            />
          </PlatformRow>
        </div>
      )}
    </div>
  );
};
