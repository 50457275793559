import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration";
import { AxiosError } from "axios";
import { messageToError, ValidationError } from "utils/validation";
import { toast } from "react-toastify";
import { getIntegrationSellers } from "services/chatIntegrationService";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { IntegrationSeller } from "models/integrationSeller";

export type BrevoIntegrationForm = {
  name: string;
  type: ChatIntegrationType;
  brevo: {
    token: string;
    sellers: string[];
  };
};

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined,
  handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    setError,
    watch,
  } = useForm<BrevoIntegrationForm>();

  const [step, setStep] = useState(0);
  const [loadingForm, setLoadingForm] = useState(false);
  const [sellers, setSelers] = useState<IntegrationSeller[]>([]);

  const selectedSellers = watch("brevo.sellers") ?? [];

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" },
    });
  }, [register]);

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "");
    setValue("type", selectedIntegration?.type ?? "brevo");
    setValue("brevo.token", selectedIntegration?.brevo?.token ?? "");
    setValue("brevo.sellers", selectedIntegration?.brevo?.sellers ?? []);
  }, [selectedIntegration, setValue]);

  async function handleSubmitFormFirstStep(data: BrevoIntegrationForm) {
    if (loadingForm) return;
    setLoadingForm(true);

    try {
      toast.info("Buscando os vendedores...");
      const { attendents } = await getIntegrationSellers({
        type: data.type as ChatIntegrationType,
        brevo: {
          token: data.brevo.token,
        },
      });
      setSelers(attendents);
      setLoadingForm(false);
      setStep(1);
      toast.success("Vendedores buscados com sucesso!");
    } catch (error) {
      toast.error(errorMessage(error as Error));
      setLoadingForm(false);
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError;
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as {
        errors: ValidationError[] | undefined;
      };

      if (data.errors) {
        const fields = data.errors.map((error) => {
          setError(error.key as keyof BrevoIntegrationForm, {
            message: messageToError(error.message),
          });
          return error.key;
        });
        return `Os campos ${fields.join(", ")} são inválidos`;
      }
      if (_err.response.status === 400)
        return "O domínio ou o token são inválidos";
    }
    return "Ocorreu um erro, por favor tente novamente";
  };

  async function handleSubmitFormSecondStep(data: BrevoIntegrationForm) {
    if (loadingForm) return;
    setLoadingForm(true);
    await handleSaveOrUpdate(data);
    setLoadingForm(false);
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    step,
    sellers,
    selectedSellers,
  };
};
