import { Integration } from "models/integration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SaveOrUpdateCampaignsIntegrationFunction } from "../../viewModel";
import { TextField } from "components/Forms/TextField";
import { Button } from "components/Button";
import { CopyText } from "components/Forms/CopyText";
import { useTranslation } from "react-i18next";

export type GoogleIntegrationForm = {
  type: string;
  name: string;
  sheetId: string;
};

type Props = {
  selectedIntegration: Integration | undefined;
  onSaveOrUpdate: SaveOrUpdateCampaignsIntegrationFunction;
  onClose: () => void;
};

export const GoogleForm = ({
  selectedIntegration,
  onClose,
  onSaveOrUpdate,
}: Props) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<GoogleIntegrationForm>();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    register("type", {
      required: {
        value: true,
        message: t("providers.view.integrations.form.validation.invalid_type"),
      },
    });
  }, [register]);

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "");
    setValue("type", selectedIntegration?.type ?? "google");
    setValue("sheetId", selectedIntegration?.sheetId ?? "");
  }, [selectedIntegration, setValue]);

  const handleSubmitForm = async (data: GoogleIntegrationForm) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      toast.info(
        selectedIntegration
          ? t("providers.view.integrations.form.update_loading")
          : t("providers.view.integrations.form.creating_loading")
      );
      await onSaveOrUpdate(data);
      toast.success(
        selectedIntegration
          ? t("providers.view.integrations.form.update_success")
          : t("providers.view.integrations.form.creating_loading")
      );
      onClose();
    } catch (error) {
      toast.error(t("providers.view.integrations.form.error"));
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="flex flex-col gap-5"
    >
      <TextField
        label={t("providers.view.integrations.form.fields.account_name")}
        placeholder={t(
          "providers.view.integrations.form.fields.account_name_placeholder"
        )}
        error={errors.name?.message}
        register={register("name", {
          required: {
            value: true,
            message: t(
              "providers.view.integrations.form.fields.account_name_required"
            ),
          },
        })}
        variant="secondary"
      />
      <TextField
        label={t("providers.view.integrations.form.fields.sheet_identifier")}
        placeholder={t(
          "providers.view.integrations.form.fields.sheet_identifier_placeholder"
        )}
        error={errors.sheetId?.message}
        register={register("sheetId", {
          required: {
            value: true,
            message: t(
              "providers.view.integrations.form.fields.sheet_identifier_required"
            ),
          },
        })}
        variant="secondary"
      />
      <CopyText
        label={t("providers.view.integrations.form.fields.integration_email")}
        text="delipe-sheets@glaper-afiliado.iam.gserviceaccount.com"
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          {t("actions.cancel")}
        </Button>
        <Button loading={isLoading} theme="highlight">
          {selectedIntegration ? t("actions.edit") : t("actions.add")}
        </Button>
      </div>
    </form>
  );
};
