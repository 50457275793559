import { Button } from "components/Button"
import { Link } from "react-router-dom"

type PageHeaderAction = {
  label: string
  leftIcon?: string
  onClick: () => void
}

type PageHeaderProps = {
  backPath?: string
  title: string
  action?: PageHeaderAction
  filter?: React.ReactNode
}

export function PageHeader({ backPath, title, action, filter }: PageHeaderProps) {

  if (backPath) {
    return (
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-4">
          <Link className="flex items-center gap-4" to={backPath}>
            <img src="/icons/page/arrow_back.svg" alt="Voltar" />
            <h1 className="text-3xl leading-none">{title}</h1>
          </Link>
          {filter}
        </div>
        { action && <PageHeaderAction {...action} />}
      </div>
    )
  }

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-4">
        <h1 className="text-3xl">{title}</h1>
        {filter}
      </div>
      { action && <PageHeaderAction {...action} />}
    </div>
  )
}

const PageHeaderAction = ({ label, leftIcon, onClick }: PageHeaderAction) => {
  return (
    <Button leftIcon={leftIcon} onClick={onClick} className="min-w-[7rem]">
      {label}
    </Button>
  )
}