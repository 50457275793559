import { PROVIDER_STATUS, statusToText } from "models/provider";

import { Pagination } from "components/Tables/Pagination";
import { Table } from "components/Tables/Table";
import { ConfirmDialog } from "components/ConfirmDialog";

import { useViewModel } from "./viewModel";
import { Masks, maskText } from "utils/mask";
import { PageHeader } from "components/Page/PageHeader";
import { PageFilter } from "components/Page/PageFilter";
import { Link } from "react-router-dom";
import { Button } from "components/Button";
import { Select } from "components/Forms/Select";
import { ResetPasswordDialog } from "components/ResetPasswordDialog";

export default function Clients() {
  const {
    navigate,
    providers,
    currentPage,
    numberOfPages,
    confirmDialogData,
    handleChangeStatus,
    setCurrentPage,
    isLoading,
    isError,
    retry,
    t,
    filterOptions,
    handleChangeFilter,
    selectedFilter,
    showResetPasswordDialog,
    handleCloseResetPasswordDialog,
    selectedUserEmail,
    handleResetPassword,
  } = useViewModel();

  return (
    <div>
      {confirmDialogData && <ConfirmDialog {...confirmDialogData} />}
      <div className="flex justify-between items-center">
        <PageHeader title={t("sidebar.clients")} filter={<Select
          options={filterOptions}
          onChange={handleChangeFilter}
          selected={selectedFilter}
        />} />
        <div className="flex items-center gap-3">
          <PageFilter hideAllFilters />
          <Link to={`/clientes/novo?page=${currentPage}&filter=${selectedFilter}`}>
            <Button leftIcon="/icons/form/add.svg">{t("actions.add")}</Button>
          </Link>
        </div>
      </div>
      <Table
        className="mt-7"
        headers={t("providers.table.headers", { returnObjects: true })}
        fields={[
          { key: "name" },
          { key: "client" },
          { key: "whatsapp" },
          { key: "responsible" },
          { key: "status", type: "status" },
        ]}
        data={providers}
        transforms={[
          {
            key: "status",
            transform: (provider) => statusToText(provider.status, t),
          },
          {
            key: "whatsapp",
            transform: (provider) =>
              maskText(
                provider.whatsapp,
                Masks.PHONE,
                provider.whatsapp.length
              ),
          },
        ]}
        overrides={[
          {
            key: "responsible",
            override: (index) => (
              <span className="w-full text-center desktop:text-left">
                {providers![index].responsible ?? "-"}
              </span>
            ),
          },
        ]}
        isLoading={isLoading}
        hasError={isError}
        onRetry={retry}
        actions={(provider, index) => {
          return (
            <>
              <button onClick={() => navigate(`/clientes/${provider.id}?page=${currentPage}&filter=${selectedFilter}`)}>
                {t("actions.show_more")}
              </button>
              <button
                onClick={() => navigate(`/clientes/editar/${provider.id}?page=${currentPage}&filter=${selectedFilter}`)}
              >
                {t("actions.edit")}
              </button>
              {provider.status !== PROVIDER_STATUS.Ativo && (
                <button
                  onClick={() =>
                    handleChangeStatus(index, PROVIDER_STATUS.Ativo)
                  }
                >
                  {t("actions.activate")}
                </button>
              )}
              {provider.status !== PROVIDER_STATUS.Inativo && (
                <button
                  onClick={() =>
                    handleChangeStatus(index, PROVIDER_STATUS.Inativo)
                  }
                >
                  {t("actions.inactivate")}
                </button>
              )}
              {provider.status !== PROVIDER_STATUS.Pausado && (
                <button
                  onClick={() =>
                    handleChangeStatus(index, PROVIDER_STATUS.Pausado)
                  }
                >
                  {t("actions.pause")}
                </button>
              )}
              <button
                onClick={() =>
                  handleResetPassword(providers![index].email)
                }
              >
                {t("actions.reset_password")}
              </button>
            </>
          );
        }}
      />
      <Pagination
        currentPage={currentPage}
        handleChangePage={setCurrentPage}
        numberOfPages={numberOfPages ?? 0}
      />
      {showResetPasswordDialog && selectedUserEmail && <ResetPasswordDialog onClose={handleCloseResetPasswordDialog} selectedUserEmail={selectedUserEmail} />}
    </div>
  );
}
