import { useEffect, useRef, useState } from "react";
import { LeadCard } from "models/lead";
import useAuth from "hooks/useAuth";
import {
  getLeadsBoardAPI,
  getLeadsBoardLoadMoreAPI,
} from "services/leadService";
import { BoardColumn } from "models/board";
import { toast } from "react-toastify";
import { useFilters } from "hooks/useFilters";
import { Seller } from "models/seller";
import { useSelector } from "react-redux";
import { IState } from "store";
import { Log } from "utils/log";
import { useTranslation } from "react-i18next";

export const useViewModel = () => {
  const { selectedProvider, user, selectedSeller } = useAuth();
  const [leadsColumns, setLeadsColumns] = useState<BoardColumn<LeadCard>[]>([]);
  const [screenState, setScreenState] = useState<
    "loading" | "success" | "error"
  >("loading");
  const [refetching, setRefetching] = useState(false);
  const { selectedDate, selectedLeadPeriodFilter } = useFilters();
  const [sellers, setSellers] = useState<Seller[]>([]);
  const { originFilters, filterOption } = useSelector(
    (state: IState) => state.boardFilters
  );
  const [lastUpdateAt, setLastUpdateAt] = useState<Date>();
  const ref = useRef<AbortController>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (selectedProvider.id) {
      const controller = new AbortController();
      fetchLeadsBoard(controller);

      return () => {
        controller.abort();
      };
    }
  }, [
    selectedProvider.id,
    selectedDate,
    filterOption,
    originFilters,
    selectedLeadPeriodFilter,
    selectedSeller,
  ]);

  const refetch = async () => {
    setRefetching(true);
    try {
      const { columns, sellers } = await getLeadsBoardAPI({
        perPage: 25,
        signal: ref.current?.signal,
        initDate:
          filterOption !== "all"
            ? selectedDate?.initDate.toISOString()
            : undefined,
        endDate:
          filterOption !== "all"
            ? selectedDate?.endDate.toISOString()
            : undefined,
        origins: originFilters.length > 0 ? originFilters : undefined,
        leadPeriodFilter: selectedLeadPeriodFilter,
        onlySelled: filterOption === "selled",
        seller: selectedSeller,
      });

      setLastUpdateAt(new Date());
      setLeadsColumns(columns);
      setSellers(sellers);
    } catch (error) {
      const _error = error as Error;
      if (_error.message !== "canceled") {
        Log.error("Error on fetchLeadsBoard", error as Error);
      }
    }
    setRefetching(false);
  };

  const retry = () => fetchLeadsBoard();

  const fetchLeadsBoard = (
    controller: AbortController | undefined = undefined
  ) => {
    setScreenState("loading");
    getLeadsBoardAPI({
      perPage: 25,
      signal: controller?.signal,
      initDate:
        filterOption !== "all"
          ? selectedDate?.initDate.toISOString()
          : undefined,
      endDate:
        filterOption !== "all"
          ? selectedDate?.endDate.toISOString()
          : undefined,
      origins: originFilters.length > 0 ? originFilters : undefined,
      leadPeriodFilter: selectedLeadPeriodFilter,
      onlySelled: filterOption === "selled",
      seller: selectedSeller,
    })
      .then(({ columns, sellers }) => {
        setLeadsColumns(columns);
        setSellers(sellers);
        setScreenState("success");
        setLastUpdateAt(new Date());
      })
      .catch((error) => {
        const _error = error as Error;
        if (_error.message !== "canceled") {
          Log.error("Error on fetchLeadsBoard", error);
          setScreenState("error");
        }
      });
  };

  const handleLoadMore =
    (index: number) =>
    async (forceRetry = false) => {
      const column = leadsColumns[index];

      if (
        column.hasMore &&
        (column.state !== "error" || forceRetry) &&
        column.state !== "loading"
      ) {
        const newColumns = [...leadsColumns];
        newColumns[index] = {
          ...column,
          state: "loading",
        };
        setLeadsColumns(newColumns);

        try {
          const { items, hasMore } = await getLeadsBoardLoadMoreAPI({
            perPage: 25,
            columnId: column.id,
            lastPosition: column.items[column.items.length - 1].position,
            initDate:
              filterOption === "date"
                ? selectedDate?.initDate.toISOString()
                : undefined,
            endDate:
              filterOption === "date"
                ? selectedDate?.endDate.toISOString()
                : undefined,
            leadPeriodFilter: selectedLeadPeriodFilter,
            origins: originFilters.length > 0 ? originFilters : undefined,
            onlySelled: filterOption === "selled",
            seller: selectedSeller,
          });

          const newColumns = [...leadsColumns];
          newColumns[index] = {
            ...column,
            items: [...column.items, ...items],
            hasMore,
            state: "success",
          };

          setLeadsColumns(newColumns);
        } catch (error) {
          Log.error("Error on handleLoadMore", error as Error);
          toast.error(t("leads.board.load_more_error"));
          const newColumns = [...leadsColumns];
          newColumns[index] = {
            ...column,
            state: "error",
          };
          setLeadsColumns(newColumns);
        }
      }
    };

  return {
    t,
    i18n,
    sellers,
    leadsColumns,
    screenState,
    fetchLeadsBoard,
    setLeadsColumns,
    handleLoadMore,
    filterOption,
    originFilters,
    user,
    retry,
    refetching,
    lastUpdateAt,
    refetch,
  };
};
