import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";

export type TableRowTypes = "text" | "status" | "money" | "number";

export type TableRowFieldProps = {
  children: string | React.ReactNode | undefined;
  type?: TableRowTypes;
  className?: string;
};

type TableItemStatus = "Ativo" | "Inativo" | "Pausa";

export function TableRowField({
  children,
  type = "text",
  className,
}: TableRowFieldProps) {
  const { t, i18n } = useTranslation();
  const { selectedProvider } = useAuth();

  const statusClasses = {
    [t("providers.status.active")]: "bg-active",
    [t("providers.status.inactive")]: "bg-inactive",
    [t("providers.status.paused")]: "bg-paused",
  };

  if (children === null || children === undefined) {
    return <td className={className}>-</td>;
  }

  if (type === "text") {
    return (
      <td className={className}>
        <span className="flex items-center gap-2">{children}</span>
      </td>
    );
  }

  if (type === "number") {
    return (
      <td className={className}>
        <span className="flex items-center gap-2">
          {Intl.NumberFormat(i18n.language).format(
            parseFloat(children as string)
          )}
        </span>
      </td>
    );
  }

  if (type === "money") {
    return (
      <td className={className}>
        <span className="flex items-center gap-2">
          {Intl.NumberFormat(i18n.language, {
            currency: selectedProvider.currency,
            style: "currency",
          }).format(parseFloat(children as string))}
        </span>
      </td>
    );
  }

  return (
    <td className={className}>
      <p
        className={[
          "text-center rounded-full text-sm w-20 h-6 leading-6",
          statusClasses[children as TableItemStatus],
        ].join(" ")}
      >
        {children}
      </p>
    </td>
  );
}
