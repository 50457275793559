import { api } from "./api"

type DashboardSocialOrigin = 'facebook' | 'google' | 'organic' 

type LeadBoardColumnTagValue = 'pending' | 'waiting_cep' | 'out_area' | 'already_client' | 'finance' | 'negotiation' | 'sold' | 'gave_up' | 'stopped_responding' | 'scheduled' | 'lost_lead'

type DashboardGroupedResume = {
  leads: number;
  leadsCost: number;
  leadsDailyCost: number;
  leadsPercentage: number;
}

type DashboardComputedResume = {
  leads: number;
  leadsPercentage: number;
}

type DashboardFinancialResume = {
  roi: number;
  mrr: number;
}

export type DashboardData = {
  total: {
    dailyInvested: number;
    invested: number;
    leads: number;
    leadsCost: number;
    dailyLeads: number;
    dailyLeadCost: number;
  }
  financial: DashboardFinancialResume
  byArea: {
    insideArea: DashboardGroupedResume
    outsideArea: DashboardGroupedResume
    pendingGrouped: DashboardGroupedResume
  }
  byStatus: {
    [key in LeadBoardColumnTagValue]: DashboardGroupedResume
  }
  byComputed: {
    open: DashboardComputedResume
    lostOpportunity: DashboardComputedResume
    sellsOpportunity: DashboardComputedResume
    sells: {
      total: number;
      soldGrouped: number;
      oldSoldGrouped: number;
      sellCost: number;
      convertionRate: number;
    }
  }
}

type DashboardResponse = {
  [key in DashboardSocialOrigin]: DashboardData
} & {
  total: DashboardData
}

export const getDashboardLeadsData = async (
  initDate: Date, endDate: Date, selectedSeller: string
): Promise<DashboardResponse> => {
  const query = new URLSearchParams({
    initDate: initDate.toISOString(),
    endDate: endDate.toISOString(),
    selectedSeller
  })

  const { data } = await api.get<{ data: DashboardResponse }>(
    `v2/dashboard?${query.toString()}`
  )

  return data.data
}
