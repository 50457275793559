import { AxiosError } from "axios";
import { Button } from "components/Button";
import { Dialog } from "components/Dialog";
import { TextArea } from "components/Forms/TextArea";
import { LeadCard } from "models/lead";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateLeadDescription } from "services/leadService";
import { Log } from "utils/log";

export type LeadForm = {
  description: string;
};

type Props = {
  lead: LeadCard;
  onSave: (lead: LeadCard) => void;
  onClose: () => void;
};

export const LeadOutOfAreaForm = ({ lead, onSave, onClose }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LeadForm>({
    defaultValues: {
      description: lead.description,
    },
  });

  const onSubmit = async (data: LeadForm) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      await updateLeadDescription(lead.id, data.description);
      onSave({
        ...lead,
        description: data.description,
      });
    } catch (error) {
      const _error = error as AxiosError;

      if (_error.isAxiosError) {
        const { status } = _error.response!;

        if (status === 403) {
          toast.error(
            t("leads.board.errors.already_attributed_seller", {
              name: lead.name,
            })
          );
          Log.error("Error on update lead", _error);
          return;
        }
      }

      toast.error(t("leads.board.errors.edit_lead", { name: lead.name }));
      Log.error("Error on update lead", error as Error);
    }

    setIsLoading(false);
  };

  return (
    <Dialog onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <TextArea
          label={t("leads.form.description")}
          placeholder={t("leads.form.description")}
          error={errors.description?.message}
          register={register("description", {
            required: {
              value: true,
              message: t("leads.form.description_required"),
            },
          })}
          variant="secondary"
        />
        <div className="flex items-center justify-end gap-2 mt-4">
          <Button theme="cancel" type="button" onClick={onClose}>
            {t("actions.cancel")}
          </Button>
          <Button theme="highlight" className="px-5" loading={isLoading}>
            {t("actions.edit")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
