import { Provider } from "./provider";

export type APIReport = Omit<
  Report,
  "initDate" | "endDate" | "createdAt" | "updatedAt"
> & {
  initDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  language?: string;
};

export type ReportType = "lead.list" | "lead.list.updated" | "lead.list.selled";

export default class Report {
  constructor(
    readonly id: string,
    readonly provider: Provider,
    readonly type: ReportType,
    readonly status: "pending" | "success" | "error",
    readonly initDate: Date,
    readonly endDate: Date,
    readonly language: string | undefined,
    readonly path: string | null,
    readonly createdAt: Date,
    readonly updatedAt: Date
  ) {}

  static fromAPI(apiReport: APIReport): Report {
    return new Report(
      apiReport.id,
      apiReport.provider,
      apiReport.type,
      apiReport.status,
      new Date(apiReport.initDate),
      new Date(apiReport.endDate),
      apiReport.language,
      apiReport.path,
      new Date(apiReport.createdAt),
      new Date(apiReport.updatedAt)
    );
  }

  get downloadUrl() {
    return `${import.meta.env.VITE_ASSETS_URL}/${this.path}`;
  }

  get dateRange() {
    return `${this.initDate.toLocaleDateString()} - ${this.endDate.toLocaleDateString()}`;
  }

  get createdAtFormatted() {
    return this.createdAt.toLocaleString();
  }
}
