export const findIndexOrFirst = <T>(array: T[], predicate: (item: T) => boolean) => {
  const index = array.findIndex(predicate);
  return index === -1 ? 0 : index;
}

export const delay = (duration: number) => new Promise(resolve => setTimeout(resolve, duration));

export const valueIsDefined = <T>(value: T) => {
  if (value === undefined || value === null) {
    return false;
  }

  return true;
}
