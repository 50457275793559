import { Button } from "components/Button";
import { DotLoading } from "components/DotLoading";
import { useTranslation } from "react-i18next";

type FormStatesProps = {
  isLoading: boolean;
  hasError: boolean;
  onRetry: () => void;
};

export const FormStates = ({
  isLoading,
  hasError,
  onRetry,
}: FormStatesProps) => {
  const { t } = useTranslation();

  if (!isLoading && !hasError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-col mt-16 gap-4 text-center">
        <p>{t("table.loading")}</p>
        <DotLoading />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center mt-16 gap-4 text-center">
      <p>{t("table.error")}</p>
      <Button onClick={onRetry}>{t("actions.retry")}</Button>
    </div>
  );
};
