import { Button } from "components/Button";
import { Link } from "react-router-dom";

export const DataDeletion = () => {
  return (
    <div className="px-8 py-8 h-screen">
      <div>
        <h1 className="text-2xl font-bold">Exclusão de Dados - Dedir</h1>
      </div>
      <div>
        <div className="space-y-4">
          <h2 className="text-sm font-bold">Última atualização: 07/11/2024</h2>
          <p>
            Em caso de encerramento de contrato ou demais motivos, você pode
            solicitar a exclusão de seus dados pessoais. Para isso, entre em
            contato conosco pelo e-mail contato@dedir.com.br.
          </p>
          <p>
            O processo será realizado em até 10 dias úteis após a solicitação.
          </p>
        </div>
      </div>
      <Link to="/">
        <Button className="mt-4.5">Ir para admin</Button>
      </Link>
    </div>
  );
};
