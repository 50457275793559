import { TFunction } from "i18next";
import { BoardTag } from "./board";
import { ChatIntegrationType } from "./chatIntegration";
import { APISeller, Seller } from "./seller";

export type ILeadSocialOrigin =
  | "facebook"
  | "google"
  | "organic"
  | "reactivation"
  | "indication"
  | "flyers"
  | "unknown";
export type ILeadChannel =
  | "whatsapp"
  | "pabx"
  | "telegram"
  | "web_chat"
  | "instagram_direct"
  | "facebook_messenger"
  | "other"
  | "google_ads";
export type ILeadDocumentType = "document" | "phone" | "id";

export const socialOrigins = (t: TFunction<"translation", undefined>, providerId: string) => {
  if (providerId === "66a2778cacbed2badd74da3f") {
    return t("leads.cittaSocialOrigins", { returnObjects: true });
  }
  return t("leads.socialOrigins", { returnObjects: true });
};

export const midiaSocialOrigins = (t: TFunction<"translation", undefined>) =>
  t("leads.adsSocialOrigin", { returnObjects: true });

export type APILeadCard = Omit<LeadCard, "id" | "seller"> & {
  _id: string;
  seller: APISeller | null;
};

type LeadIntegrationType = ChatIntegrationType | "google_sheets";

export type ILeadContantType = "phone" | "email" | "unknown";

export type Lead = {
  id: string;
  name: string;
  providerId: string;
  document: string;
  documentType: ILeadDocumentType;
  origin: LeadIntegrationType;
  integrationId: string;
  socialOrigin: ILeadSocialOrigin;
  channel: ILeadChannel;
  date: Date;
  externalId: string;
  status: string;
  position: number;
  contact?: string;
  altPhone?: string;
  seller: Seller | null;
  movedAt?: string;
  contactType: ILeadContantType;
  selledAt?: string | null;
  description: string;
  provider: {
    _id: string;
    leadsStatus: Array<{
      id: string;
      name: string;
      tag?: BoardTag;
    }>;
    user: {
      name: string;
    };
  };
  campaign?: {
    id?: string;
    title?: string;
    url?: string;
  };
};

export type LeadCard = Omit<Lead, "provider"> & {
  provider: string;
};

export type StoreLeadCard = Omit<LeadCard, "date"> & {
  date: string;
};

export const leadToStoreLeadCard = (lead: Lead): StoreLeadCard => {
  return {
    ...lead,
    provider: lead.provider._id,
    date: lead.date.toISOString(),
  };
};
