import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { DashboardData } from "services/dashboardService";
import { toPercent } from "utils/format";
import { cn } from "utils/styles";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
  showConfigModal: () => void;
};

export const GoalCard = ({ variant = "primary", data, showConfigModal }: Props) => {
  const { t, i18n } = useTranslation();
  const { selectedProvider } = useAuth();

  if (variant === "secondary") {
    return null;
  }

  if (selectedProvider.goal === 0) {
    return (
      <div
        className={cn(
          "rounded-2xl flex flex-col items-center justify-center gap-2 min-h-[7rem] laptop:flex-1 bg-primaryMultiply/70",
        )}
      >
        <div className="flex items-center gap-2.5">
          <img
            className={cn(variant === "primary" ? "" : "h-3.5")}
            src="/icons/campaigns/cart.svg"
            alt=""
          />
          <p className={cn(variant === "primary" ? "" : "text-xs")}>
            {t("providers.form.goal")}
          </p>
        </div>
        <button
          onClick={showConfigModal}
          className="text-lg text-center text-secondary"
        >
          {t("dashboard.cards.configGoal")}
        </button>
      </div>
    )
  }

  return (
    <div
      className={cn(
        "rounded-2xl flex flex-col items-center justify-center gap-2 min-h-[7rem] laptop:flex-1 bg-primaryMultiply/70",
      )}
    >
      <div className="flex items-center gap-2.5">
        <img
          className={cn(variant === "primary" ? "" : "h-3.5")}
          src="/icons/campaigns/cart.svg"
          alt=""
        />
        <p className={cn(variant === "primary" ? "" : "text-xs")}>
          {t("providers.form.goal")}
        </p>
      </div>
      <div className="font-input text-xl font-bold w-full flex flex-col gap-1 px-6 mt-4">
        <div className="w-full h-2.5 rounded-lg bg-primary/50">
          <div className="h-full bg-secondary rounded-lg max-w-full" style={{ width: `${(data.byComputed.sells.total / selectedProvider.goal) * 100}%` }} />
        </div>
        <div className="flex justify-between items-center text-sm text-center text-secondary">
          <p>{data.byComputed.sells.total}/{selectedProvider.goal}</p>
          <p>
            {toPercent(data.byComputed.sells.total / selectedProvider.goal, i18n.language)}
          </p>
        </div>
      </div>
    </div>
  );
};
