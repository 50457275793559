import { SellerForm } from "../form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../form/viewModel";
import { addSeller } from "services/sellerService";
import { queryClient } from "services/queryClient";
import { SELLER_CLIENTS_QUERY_KEY } from "../viewModel";
import { PageHeader } from "components/Page/PageHeader";
import { useTranslation } from "react-i18next";

export function NewSeller() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function handleSubmit(fields: FormFields) {
    toast(t("sellers.add.creating_loading"), { type: "info" });
    const id = await addSeller({ ...fields, password: fields.password! });
    queryClient.invalidateQueries(SELLER_CLIENTS_QUERY_KEY);
    toast(t("sellers.add.creating_success"), { type: "success" });
    navigate(`/vendedores/${id}`);
  }

  return (
    <section>
      <PageHeader title={t("sellers.add.title")} backPath="/vendedores" />
      <SellerForm
        enabled
        seller={undefined}
        onSubmit={handleSubmit}
        action={t("actions.add")}
      />
    </section>
  );
}
