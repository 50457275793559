import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";

export const useViewModel = () => {
  const { selectedProvider } = useAuth();
  const { t } = useTranslation();

  return {
    t,
    selectedProvider,
  };
};
