import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { DashboardData } from "services/dashboardService";
import { getCurrencySymbol, toCurrency } from "utils/format";
import { cn } from "utils/styles";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
};

export const LeadsCost = ({
  variant = "primary",
  data,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { selectedProvider } = useAuth();

  return (
    <div
      className={cn(
        "rounded-2xl flex-1",
        variant === "primary"
          ? "bg-primaryMultiply/70 pt-7 pb-5 px-7 min-h-40"
          : "border-white/30 border py-2 px-4 flex flex-col justify-center min-h-28"
      )}
    >
      <div
        className={cn(
          "flex items-center justify-center tablet:justify-start",
          variant === "primary" ? "gap-2.5" : "gap-1"
        )}
      >
        <img
          className={cn(variant === "primary" ? "" : "h-3.5")}
          src="/icons/campaigns/cost.svg"
          alt=""
        />
        <p className={cn(variant === "primary" ? "" : "text-xs")}>
          {t("dashboard.cards.leadsCost")}
        </p>
      </div>
      <div
        className={cn(
          "flex flex-col tablet:flex-row items-center justify-between font-input",
          variant === "primary" ? "mt-2 desktop:mt-5 gap-4" : "mt-1 gap-3"
        )}
      >
        <div className="flex flex-col items-center tablet:items-start">
          <p className="text-secondary text-xs">
            {t("dashboard.cards.generalCost")}
          </p>
          <p
            className={cn(
              "font-bold",
              variant === "primary" ? "text-xl desktop-lg:text-2xl" : "text-lg"
            )}
          >
            <span className="text-sm">
              {getCurrencySymbol(selectedProvider.currency, i18n.language)}
            </span>
            {toCurrency(
              data.total.leadsCost,
              i18n.language,
              selectedProvider.currency
            )}
          </p>
          <p className="text-secondary text-xs">
            <span className="text-xs">
              {getCurrencySymbol(selectedProvider.currency, i18n.language)}
            </span>
            {toCurrency(
              data.total.dailyLeadCost,
              i18n.language,
              selectedProvider.currency
            )}
            /{t("dashboard.cards.day")}
          </p>
        </div>
        <div className="flex flex-col items-center tablet:items-start">
          <p className="text-secondary text-xs">
            {t("dashboard.cards.insideAreaCost")}
          </p>
          <p
            className={cn(
              "font-bold",
              variant === "primary" ? "text-xl desktop-lg:text-2xl" : "text-lg"
            )}
          >
            <span className="text-sm">
              {getCurrencySymbol(selectedProvider.currency, i18n.language)}
            </span>
            {toCurrency(
              data.byArea.insideArea.leadsCost,
              i18n.language,
              selectedProvider.currency
            )}
          </p>
          <p className="text-secondary text-xs">
            <span className="text-xs">
              {getCurrencySymbol(selectedProvider.currency, i18n.language)}
            </span>
            {toCurrency(
              data.byArea.insideArea.leadsDailyCost,
              i18n.language,
              selectedProvider.currency
            )}
            /{t("dashboard.cards.day")}
          </p>
        </div>
      </div>
    </div>
  );
};
