import { PaginatedData } from "utils/pagination";
import { api } from "./api";
import Report, { APIReport, ReportType } from "models/report";

export const createReport = async (
  initDate: string,
  endDate: string,
  type: ReportType,
  language: string
) => {
  const { data: response } = await api.post<APIReport>(
    "/v1/reports/leads-list",
    {
      initDate,
      endDate,
      type,
      language,
    }
  );

  return Report.fromAPI(response);
};

export const getReports = async (
  page: number,
  perPage: number,
  selectedProvider: string
) => {
  const query = new URLSearchParams();

  query.append("page", page.toString());
  query.append("perPage", perPage.toString());

  if (selectedProvider) {
    query.append("providerId", selectedProvider);
  }

  const { data: response } = await api.get<PaginatedData<APIReport>>(
    `/v1/reports?${query.toString()}`
  );

  return {
    ...response,
    data: response.data.map((report) => Report.fromAPI(report)),
  };
};
