export type SelectedDate = {
  initDate: Date;
  endDate?: Date;
};

export type DatePeriods =
  | "today"
  | "yesterday"
  | "last7days"
  | "last14days"
  | "last30days"
  | "thisMonth"
  | "lastMonth"
  | "last6Months"
  | "thisYear"
  | "lastYear"
  | "custom";

export const DATE_PERIODS: DatePeriods[] = [
  "today",
  "yesterday",
  "last7days",
  "last14days",
  "last30days",
  "thisMonth",
  "lastMonth",
  "last6Months",
  "thisYear",
  "lastYear",
];

export type DatePeriodItem = {
  label: string;
  value: DatePeriods;
};
