import { MultiSelect } from "components/Forms/MultiSelect";
import { socialOrigins } from "models/lead";
import { IState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { updateOriginFilter } from "store/slices/boardFilters";
import { LeadPeriodFilter } from "models/filter";
import { Checkbox } from "components/Forms/Checkbox";
import { useFilters } from "hooks/useFilters";
import { updateSelectedTypeOfDate } from "store/slices/filters";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";

export type FilterOption = "date" | "all" | "selled";

export const LeadBoardFilters = () => {
  const dispatch = useDispatch();

  const { selectedProvider } = useAuth();
  const { t } = useTranslation();
  const { originFilters } = useSelector((state: IState) => state.boardFilters);
  const { selectedLeadPeriodFilter } = useFilters();

  const leadsPeriodFilters: { value: string; label: string }[] = t(
    "leads.filters.options",
    { returnObjects: true }
  );

  const handleChangeOriginsFilters = (values: string[]) => {
    dispatch(updateOriginFilter(values));
  };

  const handleChangeLeadPeriodFilter = (value: LeadPeriodFilter) => () => {
    dispatch(updateSelectedTypeOfDate(value));
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="text-base">{t("leads.filters.title")}</p>
      {leadsPeriodFilters.map((period) => (
        <Checkbox
          key={period.value}
          label={period.label}
          isChecked={period.value === selectedLeadPeriodFilter}
          onClick={handleChangeLeadPeriodFilter(period.value as LeadPeriodFilter)}
        />
      ))}
      <MultiSelect
        onChange={handleChangeOriginsFilters}
        options={socialOrigins(t, selectedProvider.id)}
        selected={originFilters}
        variant="secondary"
        clearable
        placeholder={t("leads.filters.select")}
        invertOptionsBox
      />
    </div>
  );
};
