import {
  ChatIntegration,
  ChatIntegrationOpaSuiteModel,
  ChatIntegrationSellerRelated,
  ChatIntegrationType,
} from "models/chatIntegration";

import { api } from "./api";
import { Department } from "models/department";
import { Channel } from "models/channel";
import { IntegrationSeller } from "models/integrationSeller";

type CreateOrUpdateChatIntegration = Omit<
  ChatIntegration,
  "id" | "createdAt" | "updatedAt" | "opaSuite" | "huggy"
> & {
  provider: string;
  opaSuite?: Omit<
    ChatIntegrationOpaSuiteModel,
    "webhookToken" | "webhookVerified"
  >;
  huggy?: {
    departments: string[];
    sellers: string[];
    sellersRelated: ChatIntegrationSellerRelated[];
  }
};

export const getChatIntegrations = async (
  clientId: string
): Promise<ChatIntegration[]> => {
  const { data } = await api.get<ChatIntegration[]>(
    `v1/chat-integrations?providerId=${clientId}`
  );
  return data;
};

export const createChatIntegration = async (
  integration: CreateOrUpdateChatIntegration
): Promise<ChatIntegration> => {
  const { data } = await api.post<ChatIntegration>("v1/chat-integrations", {
    ...integration,
  });
  return data;
};

export const updateChatIntegration = async (
  id: string,
  integration: CreateOrUpdateChatIntegration
): Promise<ChatIntegration> => {
  const { data } = await api.put<ChatIntegration>(
    `v1/chat-integrations/${id}`,
    {
      ...integration,
    }
  );
  return data;
};

export const deleteChatIntegration = async (
  id: string,
  providerId: string
): Promise<void> => {
  await api.delete(`v1/chat-integrations/${id}?providerId=${providerId}`);
};

type DepartmentResponse = {
  status: "success" | "error";
  departments: Department[];
  channels?: Channel[];
};

type GetIntegrationData = {
  type: ChatIntegrationType;
  token?: string;
  host?: string;
  email?: string;
  password?: string;
};

export const getDepartments = async (
  form: GetIntegrationData
): Promise<DepartmentResponse> => {
  const { data } = await api.post<DepartmentResponse>(
    `v1/chat-integrations/departments`,
    form
  );
  return data;
};

type IntegrationSellerResponse = {
  status: "success" | "error";
  attendents: IntegrationSeller[];
  channels?: Channel[];
};

type GetIntegrationSellers = {
  type: ChatIntegrationType;
  opaSuite?: {
    token: string;
    host: string;
  };
  matrix?: {
    host: string;
    login: string;
    key: string;
  };
  multi360?: {
    email: string;
    password: string;
  };
  brevo?: {
    token: string;
  };
  szChat?: {
    host: string;
    email: string;
    password: string;
    token: string;
  };
  huggy?: {
    token: string;
  }
};

export const getIntegrationSellers = async (
  form: GetIntegrationSellers
): Promise<IntegrationSellerResponse> => {
  const { data } = await api.post<IntegrationSellerResponse>(
    `v1/chat-integrations/attendents`,
    form
  );
  return data;
};

type GetIntegrationTags = {
  type: ChatIntegrationType;
  opaSuite?: {
    token: string;
    host: string;
  };
};

type IntegrationTagsResponse = {
  data: {
    id: string
    name: string
  }[]
};

export const getIntegrationTags = async (
  form: GetIntegrationTags
): Promise<IntegrationTagsResponse> => {
  const { data } = await api.post<IntegrationTagsResponse>(
    `v1/chat-integrations/tags`,
    form
  );
  return data;
};

export const webhookCheck = async (integrationId: string): Promise<boolean> => {
  const { data } = await api.get<{ data: boolean }>(
    `/v1/chat-integrations/${integrationId}/webhook/check`
  );
  return data.data;
};

export const getAuthUrl = async (
  clientId: string,
  providerId: string
): Promise<string> => {
  const { data } = await api.post<{ data: string }>(
    `/v1/chat-integrations/auth-url`,
    {
      clientId,
      providerId,
    }
  );
  return data.data;
};

type AuthCallbackResponse = {
  data: {
    sellers: IntegrationSeller[];
    integrationId: string;
  };
};

type AuthCallbackParams = {
  clientId: string;
  clientSecret: string;
  providerId: string;
  code: string;
  name: string;
};

export const authCallback = async (
  params: AuthCallbackParams
): Promise<{ sellers: IntegrationSeller[]; integrationId: string }> => {
  const { data } = await api.post<AuthCallbackResponse>(
    `/v1/chat-integrations/auth-callback`,
    params
  );

  return data.data;
};

type AuthConcludekParams = {
  sellers: string[];
  integrationId: string;
};

export const authConclude = async (
  params: AuthConcludekParams
): Promise<void> => {
  await api.post<void>(`/v1/chat-integrations/auth-conclude`, params);
};
