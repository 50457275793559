import { Link } from "react-router-dom";
import { AnimationEventHandler, useState } from "react";

import { useRoutes } from "hooks/useRoutes";

import useAuth from "hooks/useAuth";
import { getAnimationDurationInSeconds } from "utils/animation";
import { useTranslation } from "react-i18next";

export function Sidebar() {
  const { user, logout } = useAuth();
  const { routes, isActive, handleLogout } = useRoutes(user, logout);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isFirstChange, setIsFirstChange] = useState(true);
  const [afterOpen, setAfterOpen] = useState(false);
  const { t } = useTranslation();

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setIsFirstChange(false);
    if (showSidebar) {
      setAfterOpen(false);
    }
  };

  const handleOnAnimationStart: AnimationEventHandler<HTMLDivElement> = (
    animation
  ) => {
    if (animation.animationName === "open-sidebar") {
      const duration = getAnimationDurationInSeconds(animation);
      setTimeout(() => {
        setAfterOpen(true);
      }, duration / 2);
    }
  };

  return (
    <>
      <div className="w-20 tablet:w-52" />
      <div
        className={`
        ${showSidebar ? "animate-show-sidebar w-52" : "w-20"}
        ${!showSidebar && !isFirstChange ? "animate-hide-sidebar" : ""}
        fixed fill-mode-forwards tablet:w-52 h-screen block mix-blend-multiply bg-primary
      `}
        onAnimationStart={handleOnAnimationStart}
      />
      <aside
        className={`
        ${showSidebar ? "animate-show-sidebar w-52" : "w-20"}
        ${!showSidebar && !isFirstChange ? "animate-hide-sidebar" : ""}
        fill-mode-forwards tablet:w-52 fixed flex-col flex items-center pt-18 pb-14 h-screen
      `}
      >
        <img
          src="/images/brand/logo.png"
          alt="Delipe logo"
          width={150}
          className="hidden tablet:block"
        />
        <button
          onClick={handleToggleSidebar}
          className="block tablet:hidden h-10"
        >
          {!showSidebar && (
            <img src="/icons/sidebar/menu.svg" alt="Abrir menu" />
          )}
          {afterOpen && (
            <div className="flex gap-5 items-center animate-appear-text">
              <img
                src="/images/brand/logo.png"
                alt="Delipe logo"
                width={100}
                className="pb-1"
              />
              <img
                src="/icons/sidebar/close.svg"
                alt="Abrir menu"
                className="rotate-45"
                width={24}
              />
            </div>
          )}
        </button>
        <div className="flex flex-col flex-1 justify-between mt-15">
          <ul className="flex flex-col flex-1 gap-6">
            {routes.map((route) => (
              <Link
                key={route.path}
                to={route.path}
                className="w-full overflow-hidden flex justify-center"
              >
                <button
                  className={[
                    "py-2 items-center flex gap-4 hover:text-secondary cursor-pointer w-full fill-mode-forwards",
                    isActive(route.path) ? "text-secondary" : "",
                  ].join(" ")}
                >
                  <img
                    src={`/icons/sidebar/${route.icon}.svg`}
                    alt={t(`sidebar.${route.name}`)}
                  />
                  <span
                    className={`${
                      afterOpen ? "block animate-appear-text" : "hidden"
                    } tablet:block whitespace-nowrap`}
                  >
                    {t(`sidebar.${route.name}`)}
                  </span>
                </button>
              </Link>
            ))}
          </ul>
          <ul>
            <li
              className={`${
                afterOpen ? "" : "justify-center"
              } flex items-center gap-4 hover:text-secondary cursor-pointer w-full`}
              onClick={handleLogout}
            >
              <img src="/icons/sidebar/logout.svg" alt="Sair" />
              <span
                className={`${
                  afterOpen ? "block animate-appear-text" : "hidden"
                } tablet:block tablet:w-full`}
              >
                {t("sidebar.logout")}
              </span>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}
