import { useTranslation } from "react-i18next";
import { DashboardData } from "services/dashboardService";
import { cn } from "utils/styles";

type Props = {
  variant?: "primary" | "secondary";
  data: DashboardData;
};

export const Resume1Card = ({
  variant = "primary",
  data,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "rounded-2xl font-input relative flex-none desktop-xl:flex-1 flex flex-col justify-center",
        variant === "primary"
          ? "bg-primaryMultiply/70 py-5 px-6 text-sm"
          : "border border-white/30 py-4 px-4 text-xs"
      )}
    >
      <div
        className={cn(
          "flex items-center justify-between",
          variant === "primary" ? "gap-1 flex-col" : "gap-2"
        )}
      >
        <p className="text-secondary">{t("dashboard.cards.alreadyClient")}</p>
        <p className="text-lg font-bold">{data.byStatus.already_client.leads}</p>
      </div>
      <div
        className={cn(
          "flex items-center justify-between",
          variant === "primary" ? "gap-1 flex-col" : "gap-2"
        )}
      >
        <p className="text-secondary">{t("dashboard.cards.opportunity")}</p>
        <p className="text-lg font-bold">{data.byComputed.sellsOpportunity.leads}</p>
      </div>
    </div>
  );
};
