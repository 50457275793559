import { AuthInput } from "components/Auth/AuthInput";

import { useViewModel } from "./viewModel";

import { Link } from "react-router-dom";
import { Button } from "components/Button";

export default function RecoverPassword() {
  const { register, errors, handleRecoverPassword, handleSubmit, t } =
    useViewModel();

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="mix-blend-multiply bg-primary py-10 rounded-3xl w-[18.5rem] h-[20.5rem]"></div>
      <div className="absolute px-12 py-10 rounded-3xl text-center">
        <img src="/images/brand/logo.png" alt="Login logo" />
        <form
          onSubmit={handleSubmit(handleRecoverPassword)}
          className="flex flex-col gap-4 mt-7 mb-4"
        >
          <AuthInput
            label={t("login.email")}
            placeholder={t("login.email_placeholder")}
            error={errors.email?.message}
            type="email"
            leftIcon="/icons/auth/email.svg"
            register={register("email", {
              required: { value: true, message: t("login.email_required") },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t("login.email_invalid"),
              },
            })}
          />
          <Button theme="highlight" size="big" className="mt-4">
            {t("actions.recover")}
          </Button>
        </form>
        <Link to="/login" className="text-white text-xs">
          {t("recover_password.back_to_login")}
        </Link>
      </div>
    </div>
  );
}
