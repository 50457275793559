import { Button } from "components/Button";
import { Select } from "components/Forms/Select";
import { TextField } from "components/Forms/TextField";
import { useViewModel } from "./viewModel";
import { ChatIntegration } from "models/chatIntegration";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { CopyText } from "components/Forms/CopyText";
import { Label } from "components/Forms/Label";
import { Checkbox } from "components/Forms/Checkbox";
import { MultiSelect } from "components/Forms/MultiSelect";

type Props = {
  provider: string;
  selectedIntegration: ChatIntegration | undefined;
  onClose: () => void;
  onSave: SaveOrUpdateChatIntegrationFunction;
};

export const SZChatForm = ({ provider, selectedIntegration, onClose, onSave }: Props) => {
  const viewModel = useViewModel(provider, selectedIntegration, onSave);

  if (viewModel.step === 0) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5"
      >
        <TextField
          label="Nome da integração"
          placeholder="Digite o nome da integração"
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Domínio SZ Chat"
          placeholder="Digite o seu domínio personalizado do SZ Chat"
          error={viewModel.errors.szChat?.host?.message}
          register={viewModel.register("szChat.host", {
            required: { value: true, message: "O domínio é obrigatório" },
          })}
          variant="secondary"
        />
        <div className="flex gap-2">
          <TextField
            label="Email"
            placeholder="Digite o email"
            error={viewModel.errors.szChat?.email?.message}
            register={viewModel.register("szChat.email", {
              required: { value: true, message: "O email é obrigatório" },
            })}
            variant="secondary"
          />
          <TextField
            label="Senha"
            placeholder="Digite a senha"
            error={viewModel.errors.szChat?.password?.message}
            register={viewModel.register("szChat.password", {
              required: { value: true, message: "A senha é obrigatório" },
            })}
            variant="secondary"
          />
        </div>
        <TextField
          label="Token de acesso"
          placeholder="Digite o token de acesso"
          error={viewModel.errors.szChat?.token?.message}
          register={viewModel.register("szChat.token", {
            required: { value: true, message: "O token é obrigatório" },
          })}
          variant="secondary"
        />
        <div className="modalButtons">
          {viewModel.selectedIntegration ? (
            <div className="flex-1">
              <Button
                onClick={viewModel.handleShowWebhookStep}
                variant="outline"
                type="button"
              >
                Webhook
              </Button>
            </div>
          ) : null}
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  if (viewModel.step === 2) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormThirdStep)}
        className="flex flex-col gap-5 overflow-y-auto max-h-[calc(100svh-384px)]"
      >
        {viewModel.selectedSellersOptions.map((seller, index) => (
          <div key={seller.id}>
            <Select
              label={`Associe ${seller.name}`}
              onChange={(value) => {
                // Check if the internal seller is already select on the list
                const relatedSeller = viewModel.selectedSellersRelated.find(
                  (relatedSeller) => relatedSeller.seller === value
                );

                // Get current external seller
                const seller = viewModel.selectedSellersOptions[index];

                // Check if the external seller is already select on the list
                const sellerOnTheList = viewModel.selectedSellersRelated.find(
                  (relatedSeller) => relatedSeller.externalSeller === seller.name
                );

                // If external seller is selected on the list, change the internal seller related to the new selection
                if (sellerOnTheList) {
                  viewModel.setValue("szChat.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) =>
                        relatedSeller.externalSeller !== seller.name
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.name,
                    },
                  ]);
                  return;
                }

                // If the internal seller selected is on the list, change they to the current external seller
                if (relatedSeller) {
                  viewModel.setValue("szChat.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) => relatedSeller.seller !== value!
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.name,
                    },
                  ]);
                  return;
                }

                // If the internal and external seller isn't selected push it
                viewModel.setValue("szChat.sellersRelated", [
                  ...viewModel.selectedSellersRelated,
                  {
                    seller: value!,
                    externalSeller: seller.name,
                  },
                ]);
              }}
              options={viewModel.internalSellers.map((internalSeller) => ({
                value: internalSeller.id,
                label: internalSeller.name,
              }))}
              selected={
                viewModel.selectedSellersRelated.find(
                  (sellerRelated) => sellerRelated.externalSeller === seller.name
                )?.seller
              }
              error={viewModel.errors.type?.message}
              variant="secondary"
            />
          </div>
        ))}
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button
            loading={viewModel.loadingForm}
            theme="highlight"
            className="px-7"
          >
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  if (viewModel.step === 3) {
    return (
      <div className="flex flex-col gap-5">
        <CopyText
          text={`${import.meta.env.VITE_API_URL}/v1/webhooks/sz_chat/${viewModel.selectedIntegration?.id}`}
          label="URL do webhook"
          variant="secondary"
        />
        <div className="modalButtons">
          <Button onClick={viewModel.handleCancelWebhookStep} theme="cancel">
            Voltar
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <Select
        label="Selecione o departamento"
        onChange={(value) =>
          viewModel.setValue("szChat.departmentId", String(value))
        }
        options={viewModel.departments.map((department) => ({
          value: department._id,
          label: department.name,
        }))}
        selected={viewModel.departmentId}
        error={viewModel.errors.type?.message}
        variant="secondary"
      />
      <MultiSelect
        label="Selecione os vendedores"
        placeholder="Selecione os vendedores"
        onChange={(value) => viewModel.setValue("szChat.sellers", value)}
        options={viewModel.sellers.map((seller) => ({
          value: seller.name,
          label: seller.name,
        }))}
        selected={viewModel.selectedSellers}
        error={viewModel.errors.szChat?.sellers?.message}
        variant="secondary"
      />
      <div>
        <Label className="px-5">Canais de atendimento</Label>
        <div className="flex flex-col gap-1 mt-3 font-input">
          {viewModel.channels.map((channel) => {
            const isChecked = viewModel.channelsList.some(
              (item) => item.id === channel._id
            );

            return (
              <Checkbox
                isChecked={isChecked}
                label={channel.name}
                description={channel.description}
                onClick={viewModel.onToggleChannel(channel._id)}
              />
            );
          })}
        </div>
      </div>
      <div className="modalButtons">
        <Button onClick={onClose}>Cancelar</Button>
        <Button loading={viewModel.loadingForm}>Salvar</Button>
      </div>
    </form>
  );
};
