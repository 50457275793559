import { Button } from "components/Button";
import { Dialog } from "components/Dialog";
import { Select } from "components/Forms/Select";
import { TextField } from "components/Forms/TextField";
import { BoardColumn, tagList } from "models/board";
import { LeadCard } from "models/lead";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { generateUuid } from "utils/data";

type Form = {
  title: string;
  tag: string | undefined;
};

type Props = {
  beforeColumnIndex?: number;
  column: BoardColumn<LeadCard> | undefined;
  onSave: (
    column: BoardColumn<LeadCard>,
    beforeColumnIndex?: number | undefined
  ) => void;
  onClose: () => void;
};

export const LeadColumnDialogForm = ({
  column,
  onClose,
  beforeColumnIndex,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      title: column?.title ?? "",
      tag: column?.tag?.value,
    },
  });

  const tag = watch("tag");

  const handleSubmitSuccess = async ({ title, tag }: Form) => {
    if (!tag) {
      setError("tag", {
        type: "required",
        message: t("leads.board.column_edit_form.tag_required"),
      });
      return;
    }

    let _column: BoardColumn<LeadCard>;

    const _tag = tagList(t).find((t) => t.value === tag);

    if (column) {
      _column = {
        ...column,
        title,
        tag: _tag,
      };
    } else {
      _column = {
        id: generateUuid(),
        title,
        tag: _tag,
        items: [],
        state: "success",
        nbItems: 0,
        hasMore: false,
      };
    }

    onSave(_column, beforeColumnIndex);
  };

  return (
    <Dialog onClose={onClose}>
      <form
        onSubmit={handleSubmit(handleSubmitSuccess)}
        className="flex flex-col gap-5"
      >
        <TextField
          register={register("title", {
            required: t("leads.board.column_edit_form.title_required"),
          })}
          placeholder={t("leads.board.column_edit_form.title_placeholder")}
          label={t("leads.board.column_edit_form.title")}
          error={errors.title?.message}
          variant="secondary"
        />
        <Select
          label={t("leads.board.column_edit_form.tag")}
          onChange={(tag) => setValue("tag", tag)}
          selected={tag}
          options={tagList(t)}
          error={errors.tag?.message}
          placeholder={t("leads.board.column_edit_form.tag_placeholder")}
          variant="secondary"
        />
        <div className="flex items-center justify-end gap-2 mt-4">
          <Button type="button" theme="cancel" onClick={onClose}>
            {t("actions.cancel")}
          </Button>
          <Button theme="highlight" className="px-5">
            {t("actions.edit")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
