import { TFunction } from "i18next";

export type Seller = {
  id: string;
  email: string;
  name: string;
  providerId: string;
  providerName: string;
  status: number;
};

export const SELLER_STATUS = {
  Ativo: 0,
  Inativo: 1,
};

export const statusToText = (
  status: number,
  t: TFunction<"translation", undefined>
) => {
  switch (status) {
    case 0:
      return t("providers.status.active");
    case 1:
      return t("providers.status.inactive");
    default:
      return "";
  }
};

export type APISeller = {
  _id: string;
  name: string;
  email: string;
  role: "seller";
  seller: {
    provider: {
      _id: string;
      client: string;
      user: {
        _id: string;
        email: string;
        name: string;
      };
    };
    status: number;
  };
};

export const parseAPISeller = (seller: APISeller): Seller => {
  return {
    id: seller._id,
    email: seller.email,
    name: seller.name,
    providerId: seller.seller.provider._id,
    providerName: seller.seller.provider.user.name,
    status: seller.seller.status,
  };
};
