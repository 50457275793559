import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { AuthInput } from "components/Auth/AuthInput";
import { AuthCheckbox } from "components/Auth/AuthCheckbox";

import { useViewModel } from "./viewModel";

export default function Login() {
  const { register, errors, handleLogin, handleSubmit, isLoading, t } =
    useViewModel();

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="mix-blend-multiply bg-primary py-10 rounded-3xl w-80 h-[27rem]"></div>
      <div className="absolute px-12 py-10 rounded-3xl text-center w-80">
        <img src="/images/brand/logo.png" alt="Login logo" />
        <form
          onSubmit={handleSubmit(handleLogin)}
          className="flex flex-col gap-4 mt-7 mb-4"
        >
          <AuthInput
            label={t("login.email")}
            placeholder={t("login.email_placeholder")}
            error={errors.email?.message}
            type="email"
            leftIcon="/icons/auth/email.svg"
            register={register("email", {
              required: { value: true, message: t("login.email_required") },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t("login.email_invalid"),
              },
            })}
          />
          <AuthInput
            label={t("login.password")}
            placeholder={t("login.password_placeholder")}
            error={errors.password?.message}
            type="password"
            leftIcon="/icons/auth/pass.svg"
            register={register("password", {
              required: { value: true, message: t("login.password_required") },
              minLength: { value: 6, message: t("login.password_invalid") },
            })}
          />
          <AuthCheckbox />
          <Button
            theme="highlight"
            size="big"
            className="mt-4"
            loading={isLoading}
          >
            {t("actions.sign_in")}
          </Button>
        </form>
        <Link to="/recuperar" className="text-white text-xs">
          {t("login.forgot_password")}
        </Link>
      </div>
    </div>
  );
}
