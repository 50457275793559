import { Link, useParams } from "react-router-dom";

import { FormStates } from "components/Forms/FormStates";

import { getProvider } from "services/providerService";

import { CampaignsIntegration } from "./campaings-integration";

import { ChatsIntegration } from "./chats-integration";
import { SheetsIntegration } from "./sheets-integration";
import { PageHeader } from "components/Page/PageHeader";
import { ShowForm } from "./show-form";
import { useQuery } from "react-query";
import { PROVIDER_CLIENTS_QUERY_KEY } from "../viewModel";
import { useTranslation } from "react-i18next";
import { PageFilter } from "components/Page/PageFilter";
import { Button } from "components/Button";
import { useQueryParams } from "hooks/useQueryParams";

type PathParams = {
  id: string;
};

type QueryParams = {
  page?: string;
  filter?: string;
}

export function ShowClient() {
  const { id } = useParams<PathParams>();
  const { page, filter } = useQueryParams<QueryParams>();
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch } = useQuery(
    [PROVIDER_CLIENTS_QUERY_KEY, "form", id],
    () => getProvider(id!),
    { enabled: !!id }
  );

  return (
    <section>
      <div className="flex justify-between items-center">
        <PageHeader title={t("providers.view.title")} backPath={`/clientes?page=${page}&filter=${filter}`} />
        <div className="flex items-center gap-3">
          <PageFilter hideAllFilters />
          <Link to={`/clientes/editar/${id!}?page=${page}&filter=${filter}`}>
            <Button>{t("actions.edit")}</Button>
          </Link>
        </div>
      </div>
      <FormStates isLoading={isLoading} hasError={isError} onRetry={refetch} />
      {data && <ShowForm {...data} />}
      <CampaignsIntegration clientId={id!} />
      <ChatsIntegration clientId={id!} />
      <SheetsIntegration clientId={id!} />
    </section>
  );
}
