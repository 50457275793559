import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
import { SortableItem } from "./SortableItem";
import { useRef } from "react";
import { ColumnAction } from "./ColumnAction";
import { BoardColumn, cardColumnColor, tagColumnColor } from "models/board";
import { LeadCard } from "models/lead";
import { LeadCardItem } from "./LeadCardItem";
import useInfinityScroll from "./useInfinityScroll";
import { DotLoading } from "components/DotLoading";
import useAuth from "hooks/useAuth";
import { cn } from "utils/styles";
import { useTranslation } from "react-i18next";

type Props = BoardColumn<LeadCard> & {
  index: number;
  isDragging: boolean;
  onDeleteColumn: () => void;
  onAddNewColumnBefore: () => void;
  onLoadMore: (forceRetry?: boolean) => void;
  onEditLead: (lead: LeadCard) => void;
  onEditColumn: (column: BoardColumn<LeadCard>) => void;
};

export const LeadColumn = ({
  index,
  isDragging,
  onDeleteColumn,
  onAddNewColumnBefore,
  onLoadMore,
  onEditLead,
  onEditColumn,
  ...board
}: Props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { setNodeRef } = useDroppable({ id: board.id });
  const loadMoreRef = useRef<IntersectionObserver | undefined>();
  const listener = useInfinityScroll(
    loadMoreRef,
    board.state === "loading",
    onLoadMore,
    board.hasMore,
    board.items.length
  );

  const handleEditColumn = () => {
    onEditColumn(board);
  };

  const handleDeleteColumn = () => {
    onDeleteColumn();
  };

  return (
    <div className="relative border rounded-2xl border-white/30 w-72 flex flex-col min-h-[calc(100svh-16rem)]">
      {index > 0 && user?.role !== "seller" ? (
        <button
          onClick={onAddNewColumnBefore}
          className="absolute top-4 -left-6 rounded-l-md px-1.5 bg-accent-300 hover:bg-accent-400 hover:ring hover:ring-accent-500/30"
        >
          +
        </button>
      ) : null}
      <div className="py-2 px-4 bg-accent-400 rounded-tr rounded-tl flex items-center h-16">
        <div
          className={cn(
            "w-2 h-2 rounded-full",
            tagColumnColor[board.tag?.value ?? "unkown"]
          )}
        />
        <div className="flex flex-col flex-1 pl-3">
          <p className="line-clamp-1">{board.title}</p>
          <div className="flex gap-1 items-center">
            <p className="text-xs">({board.nbItems})</p>
            {board.tag ? (
              <p className="text-xs">
                {
                  t("leads.board.tags", { returnObjects: true }).find(
                    (tag) => tag.value === board.tag?.value
                  )?.label
                }
              </p>
            ) : null}
          </div>
        </div>
        {user?.role !== "seller" ? (
          <div className="flex flex-col gap-1">
            {index > 0 ? (
              <div className="flex flex-col gap-1">
                <ColumnAction onClick={handleEditColumn} icon="edit" />
                {user?.role === "admin" ? (
                  <ColumnAction icon="delete" onClick={handleDeleteColumn} />
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <SortableContext
        id={board.id}
        items={board.items}
        strategy={verticalListSortingStrategy}
      >
        <ul
          ref={setNodeRef}
          className={`flex flex-col gap-4 mt-4 px-8 overflow-y-auto min-h-[calc(100vh-25rem)] max-h-[calc(100vh-21rem)]`}
        >
          {board.items.map((lead) => (
            <li
              key={lead.id}
              className="relative"
              onClick={() => onEditLead(lead)}
            >
              <SortableItem id={lead.id}>
                <LeadCardItem
                  {...lead}
                  color={cardColumnColor[board.tag?.value ?? "unkown"]}
                />
              </SortableItem>
            </li>
          ))}
          <li ref={listener}>
            {board.state === "loading" ? (
              <div className="flex flex-col justify-center items-center py-4">
                <DotLoading />
              </div>
            ) : null}
            {board.state === "error" ? (
              <button
                onClick={() => onLoadMore(true)}
                className="w-full outline-none bg-accent-400 rounded text-white py-2 text-sm hover:ring hover:ring-accent-400/50 transform active:scale-95 transition-transform"
              >
                {t("actions.retry")}
              </button>
            ) : null}
            {board.state === "success" && board.hasMore ? (
              <div className="py-6" />
            ) : null}
          </li>
        </ul>
      </SortableContext>
    </div>
  );
};
