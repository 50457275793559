import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import useAuth from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { postConfirmRecover, postLogin } from "services/authService";
import { useQueryParams } from "hooks/useQueryParams";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type LoginFormInputs = {
  password: string;
  passwordConfirm: string;
};

export const useViewModel = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<LoginFormInputs>();
  const navigate = useNavigate();
  const { code } = useQueryParams<{ code: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const password = watch("password");

  useEffect(() => {
    if (code === undefined) {
      navigate("/404");
    }
  }, []);

  async function handleConfirmRecover({ password }: LoginFormInputs) {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await postConfirmRecover(password, code!);
      toast.success(t("confirm_recover_password.success"));
      navigate("/login");
    } catch (err) {
      const _err = err as AxiosError;
      if (_err.response?.status === 400) {
        setError("password", {
          message: t("confirm_recover_password.invalid_code"),
        });
      } else {
        setError("password", {
          message: t("confirm_recover_password.general_error"),
        });
      }
    }
    setIsLoading(false);
  }

  return {
    t,
    register,
    errors,
    handleSubmit,
    handleConfirmRecover,
    password,
    isLoading,
  };
};
