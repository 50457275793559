import { Button } from "components/Button";
import { Form, FormColumn, FormRow } from "components/Forms/Form";
import { Select } from "components/Forms/Select";
import { TextField } from "components/Forms/TextField";

import { Seller } from "models/seller";

import { FormFields, useViewModel } from "./viewModel";
import { StatusSelect } from "components/Forms/StatusSelect";

type SellerFormProps = {
  seller?: Seller;
  onSubmit?: (fields: FormFields) => Promise<void>;
  enabled: boolean;
  action?: string;
};

export function SellerForm({
  seller,
  enabled,
  action,
  onSubmit,
}: SellerFormProps) {
  const {
    handleSubmit,
    errors,
    register,
    setValue,
    loading,
    status,
    handleChangeProvider,
    providers,
    selectedProviderId,
    user,
    t,
  } = useViewModel(seller, onSubmit);

  return (
    <Form onSubmit={handleSubmit} className="mt-12 tablet:mt-24">
      <FormRow>
        <FormColumn>
          <TextField
            label={t("sellers.form.name")}
            placeholder={t("sellers.form.name_placeholder")}
            error={errors.name?.message}
            disabled={!enabled}
            register={register("name", {
              required: {
                value: true,
                message: t("sellers.form.name_required"),
              },
            })}
          />
          <TextField
            label={t("sellers.form.email")}
            type="email"
            placeholder={t("sellers.form.email_placeholder")}
            error={errors.email?.message}
            disabled={!enabled}
            register={register("email", {
              required: {
                value: true,
                message: t("sellers.form.email_required"),
              },
              pattern: {
                value: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: t("sellers.form.email_invalid"),
              },
            })}
          />
          {seller === undefined && (
            <TextField
              label={t("sellers.form.password")}
              type="password"
              placeholder={t("sellers.form.password_placeholder")}
              error={errors.password?.message}
              disabled={!enabled}
              register={register("password", {
                required: {
                  value: true,
                  message: t("sellers.form.password_required"),
                },
                minLength: {
                  value: 6,
                  message: t("sellers.form.password_invalid"),
                },
              })}
            />
          )}
          {user?.role === "admin" ? (
            <Select
              onChange={handleChangeProvider}
              options={
                providers?.map((provider) => ({
                  label: provider.name,
                  value: provider.id,
                })) ?? []
              }
              selected={selectedProviderId}
              disabled={!enabled}
              placeholder={t("sellers.form.provider_placeholder")}
              label={t("sellers.form.provider")}
              error={errors.providerId?.message}
            />
          ) : null}
          <StatusSelect
            label={t("sellers.form.status")}
            selected={String(status)}
            disabled={!enabled}
            options={Object.values(
              t("sellers.status", { returnObjects: true })
            ).map((status, index) => ({
              label: status,
              value: String(index),
            }))}
            onChange={(value) => setValue("status", Number(value))}
          />
        </FormColumn>
      </FormRow>
      {action && (
        <Button
          type="submit"
          size="default"
          loading={loading}
          className="w-48 mt-20"
        >
          {action}
        </Button>
      )}
    </Form>
  );
}
