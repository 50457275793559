import { Button } from "components/Button";
import { Dialog } from "components/Dialog";
import { DatePicker } from "components/Forms/DatePicker";
import { queryClient } from "services/queryClient";
import { REPORT_CLIENTS_QUERY_KEY } from "../viewModel";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import { createReport } from "services/reporService";
import { delay } from "utils/utils";
import { toMs } from "utils/date";
import { ReportType } from "models/report";
import { Select } from "components/Forms/Select";
import { useTranslation } from "react-i18next";

type ReportForm = {
  initDate: string;
  endDate: string;
  type: string;
};

type Props = {
  onClose: () => void;
};

export const CreateReportForm = ({ onClose }: Props) => {
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm<ReportForm>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const watchInitDate = watch("initDate");

  const { i18n } = useTranslation();

  const reportTypeOptions: { value: ReportType; label: string }[] = t(
    "reports.form.report_options",
    { returnObjects: true }
  );

  const handleCreateReport = async (data: ReportForm) => {
    if (
      isSubmitting ||
      !reportTypeOptions.some((option) => option.value === data.type)
    )
      return;

    setIsSubmitting(true);

    try {
      await createReport(
        data.initDate,
        data.endDate,
        data.type as ReportType,
        i18n.language
      );
      await delay(toMs({ milliseconds: 500 }));
      await queryClient.invalidateQueries(REPORT_CLIENTS_QUERY_KEY);
      onClose();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao criar relatório");
    }

    setIsSubmitting(false);
  };

  return (
    <Dialog onClose={onClose}>
      <form
        className="flex flex-col gap-5"
        onSubmit={handleSubmit(handleCreateReport)}
      >
        <p className="text-center">{t("reports.form.title")}</p>
        <div className="flex flex-col gap-2">
          <DatePicker
            name="initDate"
            register={register("initDate", {
              required: t("reports.form.initial_date_required"),
              validate: (value) => {
                if (new Date(value).toString() === "Invalid Date") {
                  return t("reports.form.initial_date_invalid");
                }
                if (new Date(value) > new Date()) {
                  return t("reports.form.initial_date_after_today");
                }
                return true;
              },
            })}
            variant="secondary"
            label={t("reports.form.initial_date")}
            error={errors.initDate?.message}
          />
          <DatePicker
            name="endDate"
            register={register("endDate", {
              required: t("reports.form.end_date"),
              validate: (value) => {
                if (new Date(value).toString() === "Invalid Date") {
                  return t("reports.form.end_date_invalid");
                }
                if (new Date(value) > new Date()) {
                  return t("reports.form.end_date_after_today");
                }
                if (new Date(value) < new Date(watchInitDate)) {
                  return t("reports.form.end_date_after_initial");
                }
                return true;
              },
            })}
            variant="secondary"
            label={t("reports.form.end_date")}
            error={errors.endDate?.message}
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                selected={field.value}
                options={reportTypeOptions}
                label={t("reports.form.report_type")}
                error={errors.type?.message}
                variant="secondary"
                placeholder={t("reports.form.report_type_placeholder")}
              />
            )}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button theme="cancel" type="button" onClick={onClose}>
            {t("actions.cancel")}
          </Button>
          <Button theme="highlight" type="submit" loading={isSubmitting}>
            {t("actions.generate")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
