import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ChatIntegration, ChatIntegrationType } from "models/chatIntegration";
import { AxiosError } from "axios";
import { messageToError, ValidationError } from "utils/validation";
import { toast } from "react-toastify";
import { getDepartments } from "services/chatIntegrationService";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { Department } from "models/department";

export type MyClientsIntegrationForm = {
  name: string;
  type: ChatIntegrationType;
  myClients: {
    email: string;
    password: string;
    queues: string[];
  };
};

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined,
  handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    setError,
    watch,
  } = useForm<MyClientsIntegrationForm>();

  const [step, setStep] = useState(0);
  const [loadingForm, setLoadingForm] = useState(false);
  const [queues, setQueues] = useState<Department[]>([]);

  const selectedQueues = watch("myClients.queues") ?? [];

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" },
    });
  }, [register]);

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "");
    setValue("type", selectedIntegration?.type ?? "myClients");
    setValue("myClients.email", selectedIntegration?.myClients?.email ?? "");
    setValue(
      "myClients.password",
      selectedIntegration?.myClients?.password ?? ""
    );
    setValue("myClients.queues", selectedIntegration?.myClients?.queues ?? []);
  }, [selectedIntegration, setValue]);

  async function handleSubmitFormFirstStep(data: MyClientsIntegrationForm) {
    if (loadingForm) return;
    setLoadingForm(true);

    try {
      toast.info("Buscando os vendedores...");
      const { departments } = await getDepartments({
        type: data.type as ChatIntegrationType,
        email: data.myClients.email,
        password: data.myClients.password,
      });
      setQueues(departments);
      setLoadingForm(false);
      setStep(1);
      toast.success("Vendedores buscados com sucesso!");
    } catch (error) {
      toast.error(errorMessage(error as Error));
      setLoadingForm(false);
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError;
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as {
        errors: ValidationError[] | undefined;
      };

      if (data.errors) {
        const fields = data.errors.map((error) => {
          setError(error.key as keyof MyClientsIntegrationForm, {
            message: messageToError(error.message),
          });
          return error.key;
        });
        return `Os campos ${fields.join(", ")} são inválidos`;
      }
      if (_err.response.status === 400) return "O email ou senha são inválidos";
    }
    return "Ocorreu um erro, por favor tente novamente";
  };

  async function handleSubmitFormSecondStep(data: MyClientsIntegrationForm) {
    if (loadingForm) return;
    setLoadingForm(true);
    await handleSaveOrUpdate(data);
    setLoadingForm(false);
  }

  return {
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    step,
    queues,
    selectedQueues,
  };
};
