import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import useAuth from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { postLogin } from "services/authService";
import { useTranslation } from "react-i18next";

type LoginFormInputs = {
  email: string;
  password: string;
};

export const useViewModel = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginFormInputs>();
  const { login, user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (user !== undefined) {
      navigate("/404");
    }
  }, []);

  async function handleLogin({ email, password }: LoginFormInputs) {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const { data } = await postLogin(email, password);
      login(data.token, data.user);
      navigate("/");
    } catch (err) {
      const _err = err as AxiosError;
      if (_err.response?.status === 400) {
        setError("email", { message: t("login.email_or_password_invalid") });
      } else {
        setError("email", { message: t("login.general_error") });
      }
    }
    setIsLoading(false);
  }

  return {
    t,
    register,
    errors,
    handleSubmit,
    handleLogin,
    isLoading,
  };
};
