import { FormColumn, FormRow } from "components/Forms/Form";
import { MaskedTextField } from "components/Forms/MaskedTextField";
import { StatusSelect } from "components/Forms/StatusSelect";
import { TextField } from "components/Forms/TextField";
import { Provider } from "models/provider";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Masks } from "utils/mask";

type ShowFormProps = Provider;

export const ShowForm = ({
  email,
  name,
  whatsapp,
  status,
  client,
  responsible,
}: ShowFormProps) => {
  const [whatsappPhone, setWhatsappPhone] = useState(whatsapp);
  const { t } = useTranslation();

  return (
    <FormRow className="mt-10">
      <FormColumn>
        <TextField
          label={t("providers.form.provider_name")}
          disabled
          value={name}
        />
        <TextField
          label={t("providers.form.client_name")}
          disabled
          value={client}
        />
      </FormColumn>
      <FormColumn>
        <MaskedTextField
          label={t("providers.form.client_whatsapp")}
          type="phone"
          mask={Masks.PHONE}
          disabled
          value={whatsappPhone}
          onChange={setWhatsappPhone}
        />
        <TextField
          label={t("providers.form.client_email")}
          type="email"
          disabled
          value={email}
        />
      </FormColumn>
      <FormColumn>
        <TextField
          label={t("providers.form.responsible")}
          disabled
          value={responsible}
        />
        <StatusSelect
          label={t("providers.form.status")}
          selected={String(status)}
          disabled
          options={Object.values(
            t("providers.status", { returnObjects: true })
          ).map((status, index) => ({
            label: status,
            value: index.toString(),
          }))}
        />
      </FormColumn>
    </FormRow>
  );
};
