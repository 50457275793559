import { api } from "./api";
import { IAuthUser } from "models/authUser";
import { AxiosResponse } from "axios";

type LoginResponse = {
  token: string,
  user: IAuthUser
}

export function postLogin(email: string, password: string) {
  return api.post<LoginResponse>(`/v1/auth/login`, {
    email,
    password
  })
}

export async function getUser(): Promise<AxiosResponse<IAuthUser>> {
  return api.get<IAuthUser>(`/v1/auth/me`)
}

export function postRecover(email: string) {
  return api.post(`/v1/auth/recover`, {
    email
  })
}

export function postConfirmRecover(password: string, code: string) {
  return api.post(`/v1/auth/recover/confirm`, {
    password,
    code
  })
}

export function postResetPassword(email: string, password: string) {
  return api.post(`/v1/auth/reset`, {
    email,
    password
  })
}