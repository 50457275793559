import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"

import { ChatIntegration, ChatIntegrationSellerRelated, ChatIntegrationType } from "models/chatIntegration"
import { AxiosError } from "axios"
import { messageToError, ValidationError } from "utils/validation"
import { toast } from "react-toastify"
import { getAuthUrl, getDepartments, getIntegrationSellers } from "services/chatIntegrationService"
import { Department } from "models/department"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { IntegrationSeller } from "models/integrationSeller"
import { Seller } from "models/seller"
import { getSellers } from "services/sellerService"

export type HuggyIntegrationForm = {
  name: string;
  type: ChatIntegrationType;
  huggy: {
    clientId: string;
    departments: string[];
    sellers: string[];
    sellersRelated: ChatIntegrationSellerRelated[];
  };
}

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined,
  providerId: string,
  handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction,
) => {
  const {
    register, formState: { errors }, setValue, handleSubmit, setError, getValues, watch
  } = useForm<HuggyIntegrationForm>()

  const [step, setStep] = useState(0)
  const [loadingForm, setLoadingForm] = useState(false)
  const [departments, setDepartments] = useState<Department[]>([])
  const [sellers, setSelers] = useState<IntegrationSeller[]>([]);
  const [internalSellers, setInternalSellers] = useState<Seller[]>([]);

  const huggyDepartments = watch("huggy.departments")
  const selectedSellers = watch("huggy.sellers") ?? [];
  const selectedSellersRelated = watch("huggy.sellersRelated") ?? [];

  const status = selectedIntegration
    ? selectedIntegration.huggy?.tags.length === 0
      ? 'pending' : 'old'
    : 'new'

  const selectedSellersOptions = useMemo(() => {
    return selectedSellers.map(
      (seller) => sellers.find((_seller) => _seller.id === seller)!
    );
  }, [sellers, selectedSellers]);

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "")
    setValue("type", selectedIntegration?.type ?? "huggy")
    setValue("huggy.clientId", selectedIntegration?.huggy?.clientId ?? "")
    setValue("huggy.sellersRelated", selectedIntegration?.huggy?.sellersRelated ?? [])
    setValue("huggy.departments", selectedIntegration?.huggy?.departments ?? [])
    setValue("huggy.sellers", selectedIntegration?.huggy?.sellers ?? [])

    const timeout = setTimeout(() => {
      if (!selectedIntegration?.huggy?.token || step !== 0) {
        return;
      }

      toast.info("Buscando departamentos...")

      Promise.all([
        getDepartments({
          type: "huggy",
          token: selectedIntegration.huggy.token
        }),
        getIntegrationSellers({
          type: "huggy",
          huggy: {
            token: selectedIntegration.huggy.token
          }
        })
      ]).then(([departments, sellers]) => {
        setDepartments(departments.departments)
        setSelers(sellers.attendents)
      }).catch(error => { 
        console.log(error)
        toast.error("Ocorreu um erro ao buscar os departamentos")
      })
    }, 1000)

    return () => clearTimeout(timeout)
  }, [selectedIntegration, setValue])

  async function handleSubmitFormFirstStep(data: HuggyIntegrationForm) {
    if (loadingForm) return
    setLoadingForm(true)

    if (selectedIntegration?.huggy?.token) {
      const internalSellers = await getSellers(1, 100, providerId);
      setInternalSellers(internalSellers.data);

      setLoadingForm(false);

      setStep(1);
      return;
    }

    handleGenerateAuthUrl(data.huggy.clientId)
  }

  const handleSubmitFormSecondStep = async (data: HuggyIntegrationForm) => {
    console.log('end')
    if (loadingForm) return
    setLoadingForm(true)

    await handleSaveOrUpdate({
      ...data,
      huggy: {
        ...data.huggy,
        sellersRelated: data.huggy.sellersRelated.filter((sellerRelated) =>
          data.huggy.sellers.includes(sellerRelated.externalSeller)
        ),
      }
    });
    
    setLoadingForm(false);
  }

  async function handleRefreshToken() {
    const clientId = getValues("huggy.clientId")
    handleGenerateAuthUrl(clientId)
  }

  async function handleGenerateAuthUrl(clientId: string) {
    if (loadingForm) return
    setLoadingForm(true)

    try {
      toast.info("Gerando url de autorização...")
      const url = await getAuthUrl(clientId, providerId)
      window.location.href = url
    } catch (error) {
      toast.error(errorMessage(error as Error))
      setLoadingForm(false)
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as { errors: ValidationError[] | undefined }

      if (data.errors) {
        const fields = data.errors.map(error => {
          setError(error.key as keyof HuggyIntegrationForm, { message: messageToError(error.message) })
          return error.key
        })
        return `Os campos ${fields.join(", ")} são inválidos`
      }
      if (_err.response.status === 400) return "O domínio ou o token são inválidos"
    }
    return "Ocorreu um erro, por favor tente novamente"
  }

  return {
    step,
    internalSellers,
    selectedSellersOptions,
    selectedSellersRelated,
    selectedSellers,
    sellers,
    departments,
    selectedIntegration,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    handleRefreshToken,
    status,
    huggyDepartments
  }
}