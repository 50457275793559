import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { queryClient } from "services/queryClient";

import { ProviderForm } from "../form";
import { FormFields } from "../form/viewModel";

import { PROVIDER_CLIENTS_QUERY_KEY } from "../viewModel";
import { addProvider } from "services/providerService";
import { PageHeader } from "components/Page/PageHeader";
import { PROVIDER_SELECT_QUERY } from "components/Page/PageFilter/ProviderFilter";
import { PageFilter } from "components/Page/PageFilter";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "hooks/useQueryParams";

type QueryParams = {
  page?: string;
  filter?: string;
};

export function NewClient() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { page, filter } = useQueryParams<QueryParams>();

  async function handleSubmit(fields: FormFields) {
    toast(t("providers.add.update_loading"), { type: "info" });
    const id = await addProvider(fields);
    queryClient.invalidateQueries(PROVIDER_CLIENTS_QUERY_KEY);
    queryClient.invalidateQueries(PROVIDER_SELECT_QUERY);
    toast(t("providers.add.update_success"), { type: "success" });
    navigate(`/clientes/${id!}?page=${page}&filter=${filter}`);
  }

  return (
    <section>
      <div className="flex justify-between items-center">
        <PageHeader title={t("providers.add.title")} backPath={`/clientes?page=${page}&filter=${filter}`} />
        <PageFilter hideAllFilters />
      </div>
      <ProviderForm
        enabled
        provider={undefined}
        onSubmit={handleSubmit}
        action={t("actions.add")}
      />
    </section>
  );
}
