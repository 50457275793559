import { Link, useParams } from "react-router-dom";

import { FormStates } from "components/Forms/FormStates";

import { getSeller } from "services/sellerService";

import { SellerForm } from "../form";

import { PageHeader } from "components/Page/PageHeader";
import { useQuery } from "react-query";
import { SELLER_CLIENTS_QUERY_KEY } from "../viewModel";
import { useTranslation } from "react-i18next";
import { Button } from "components/Button";
import { PageFilter } from "components/Page/PageFilter";

type QueryParams = {
  id: string;
};

export function ShowSeller() {
  const { id } = useParams<QueryParams>();
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch } = useQuery(
    [SELLER_CLIENTS_QUERY_KEY, "form", id],
    () => getSeller(id!),
    { enabled: !!id }
  );

  return (
    <div>
      <div className="flex justify-between items-center">
        <PageHeader title={t("sellers.see.title")} backPath="/vendedores" />
        <div className="flex items-center gap-3">
          <PageFilter hideAllFilters />
          <Link to={`/vendedores/editar/${id!}`}>
            <Button>{t("actions.edit")}</Button>
          </Link>
        </div>
      </div>
      <FormStates isLoading={isLoading} hasError={isError} onRetry={refetch} />
      {data && <SellerForm enabled={false} seller={data} />}
    </div>
  );
}
