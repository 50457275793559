import { Button } from "components/Button";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  return (
    <div className="w-full max-w-4xl px-8 py-8">
      <div>
        <h1 className="text-2xl font-bold">Política de Privacidade - Dedir</h1>
      </div>
      <div>
        <div className="space-y-4">
          <h2 className="text-sm font-bold">Última atualização: 07/11/2024</h2>
          <p>
            Esta Política de Privacidade rege o uso da aplicação Dedir e se
            aplica a todas as informações coletadas sobre você, de acordo com a
            Lei Geral de Proteção de Dados (LGPD) e a legislação brasileira
            vigente.
          </p>
          <p>
            Ao acessar, navegar ou utilizar nossos serviços, você concorda com
            os termos aqui estabelecidos. Portanto, leia atentamente esta
            política e entre em contato caso tenha qualquer dúvida.
          </p>
          Copy{" "}
          <h2 className="text-xl font-bold">Coleta e Uso de Informações</h2>
          <p>Coletamos diversos tipos de informações sobre você, incluindo:</p>
          <ul className="list-disc pl-6">
            <li>
              Informações pessoais: Nome, e-mail, número de telefone,
              entre outras.
            </li>
            <li>
              Informações de uso: Interações com a aplicação, preferências,
              histórico de atividades, entre outras.
            </li>
          </ul>
          <p>Utilizamos essas informações para:</p>
          <ul className="list-disc pl-6">
            <li>Fornecer, personalizar e melhorar nossos serviços.</li>
            <li>
              Enviar comunicações, como atualizações, promoções e suporte.
            </li>
            <li>Analisar o uso da aplicação para aprimorá-la.</li>
            <li>Cumprir obrigações legais e regulatórias.</li>
          </ul>
          <h2 className="text-xl font-bold">
            Uso de Cookies e Tecnologias Similares
          </h2>
          <p>
            Não utilizamos cookies ou tecnologias similares na aplicação Dedir.
          </p>
          <h2 className="text-xl font-bold">Compartilhamento de Informações</h2>
          <p>Podemos compartilhar suas informações com terceiros, como:</p>
          <ul className="list-disc pl-6">
            <li>
              Provedores de Serviços: Empresas que nos auxiliam no funcionamento
              da aplicação, como processamento de pagamentos, armazenamento de
              dados e suporte técnico.
            </li>
            <li>
              Anunciantes: Utilizamos os serviços de publicidade do Google Ads e
              Facebook Ads para exibir anúncios relevantes. Esses serviços
              coletam e processam informações sobre sua navegação e interações
              com os anúncios, com seu consentimento prévio.
            </li>
            <li>
              Autoridades Governamentais: Quando necessário para cumprir
              requisições legais ou regulatórias.
            </li>
          </ul>
          <p>
            Em qualquer caso, garantimos que essas empresas cumprem com as
            mesmas políticas de privacidade e segurança que adotamos.
          </p>
          <h2 className="text-xl font-bold">Segurança e Proteção de Dados</h2>
          <p>
            Implementamos medidas técnicas e organizacionais para proteger suas
            informações contra acesso, uso, alteração ou destruição não
            autorizados. No entanto, nenhum sistema é completamente seguro,
            então não podemos garantir a total proteção de seus dados.
          </p>
          <h2 className="text-xl font-bold">Seus Direitos</h2>
          <p>De acordo com a LGPD, você tem o direito de:</p>
          <ul className="list-disc pl-6">
            <li>
              Acessar, corrigir, atualizar ou excluir suas informações pessoais.
            </li>
            <li>Limitar ou opor-se ao processamento de suas informações.</li>
            <li>
              Retirar seu consentimento para o uso de suas informações a
              qualquer momento.
            </li>
            <li>
              Receber uma cópia de suas informações em um formato estruturado.
            </li>
            <li>
              Apresentar reclamações a uma autoridade de proteção de dados.
            </li>
          </ul>
          <p>
            Para exercer esses direitos, entre em contato conosco através dos
            canais disponíveis.
          </p>
          <h2 className="text-xl font-bold">Alterações nesta Política</h2>
          <p>
            Podemos atualizar esta Política de Privacidade periodicamente. Caso
            haja alterações significativas, iremos notificá-lo. A data da última
            atualização estará sempre disponível no topo deste documento.
          </p>
          <h2 className="text-xl font-bold">Contato</h2>
          <p>
            Se você tiver qualquer dúvida, sugestão ou preocupação sobre nossa
            Política de Privacidade, entre em contato conosco pelo e-mail dev@delipe.com.br
          </p>
        </div>
      </div>
      <Link to="/">
        <Button className="mt-4.5">Ir para admin</Button>
      </Link>
    </div>
  );
};
