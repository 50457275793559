import { useTranslation } from "react-i18next";
import { cn } from "utils/styles";

type Props = {
  plataform: "meta_ads" | "google_ads" | "organic";
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

export const PlatformRow = ({ plataform, children, className }: Props) => {
  const { t } = useTranslation();

  const platafomIcon = {
    meta_ads: "/icons/campaigns/facebook.svg",
    google_ads: "/icons/campaigns/googlev2.svg",
    organic: "/icons/campaigns/organic.svg",
  }[plataform];

  const plataformName = {
    meta_ads: "Meta",
    google_ads: "Google",
    organic: t("dashboard.cards.organic"),
  }[plataform];

  return (
    <div
      className={cn(
        "bg-primaryMultiply rounded-2xl pl-4 pr-2 desktop-lg:px-8 py-4 flex flex-col desktop:flex-row gap-4 desktop:gap-0",
        className
      )}
    >
      <div className="flex items-center gap-2 justify-center desktop:justify-start w-full desktop:w-40 desktop-lg:w-44">
        <div className="w-10">
          <img src={platafomIcon} alt={plataformName} className="h-8" />
        </div>
        <p className="text-xl">{plataformName}</p>
      </div>
      {children}
    </div>
  );
};
