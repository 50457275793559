import { useState } from "react";
import { Button } from "./Button";
import { TextField } from "./Forms/TextField";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { postResetPassword } from "services/authService";

type Props = {
  selectedUserEmail: string;
  onClose: () => void;
}

export const ResetPasswordDialog = ({ selectedUserEmail, onClose }: Props) => {

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = async () => {
    setIsLoading(true);
    
    if (password !== confirmPassword) {
      toast.error("As senhas não correspondem");
      setIsLoading(false);
      return;
    }

    if (password.length < 8) {
      toast.error("A senha deve ter pelo menos 8 caracteres");
      setIsLoading(false);
      return;
    }

    try {
      await postResetPassword(selectedUserEmail, password);
      toast.success(t("reset_password.success"));
      onClose();
    } catch (error) {
      toast.error(t("reset_password.error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="modalBackground" onClick={onClose}></div>
      <div className="fixed top-36 left-1/2 transform -translate-x-1/2 shadow-md py-8 px-12 rounded-xl w-96 z-50 bg-modal flex flex-col gap-4">
        <TextField
          label={t("reset_password.password")}
          name="password"
          type="password"
          placeholder={t("reset_password.password")}
          variant="secondary"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label={t("reset_password.confirm_password")}
          name="confirmPassword"
          type="password"
          placeholder={t("reset_password.confirm_password")}
          variant="secondary"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          variant="default"
          onClick={handleResetPassword}
          disabled={isLoading}
        >
          {isLoading ? t("reset_password.loading") : t("reset_password.reset")}
        </Button>
      </div>
    </div>
  )
};
