import { MoneyTextField } from "components/Forms/MoneyTextField";
import { createPortal } from "react-dom"
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import useAuth from "hooks/useAuth";
import { Button } from "components/Button";
import { useState } from "react";
import { Masks, unMaskMoneyToNumber } from "utils/mask";
import { ProviderConfigs, updateProviderConfigs } from "services/providerService";
import { toast } from "react-toastify";
import { queryClient } from "services/queryClient";
import { DASHBOARD_KEY } from "../Dashboard/viewModel";
import { delay } from "utils/utils";
import { PROVIDER_CLIENTS_QUERY_KEY } from "pages/clients/viewModel";
import { MaskedTextField } from "components/Forms/MaskedTextField";

const schema = z.object({
  averageTicket: z.string()
    .refine((value) => unMaskMoneyToNumber(value) > 0, { message: "providers.form.validation.average_ticket_invalid" }),
  goal: z.string()
    .refine((value) => Number(value) > 0, { message: "providers.form.validation.goal_invalid" })
});

export type ConfigModalData = z.infer<typeof schema>

type ConfigModalProps = {
  handleClose: () => void;
}

export const ConfigModal = ({ handleClose }: ConfigModalProps) => {
  const { t } = useTranslation();
  const { selectedProvider, handleUpdateSelectedProviderConfigs } = useAuth()
  const { handleSubmit, watch, formState: { errors }, setValue } = useForm<ConfigModalData>({
    resolver: zodResolver(schema),
    defaultValues: {
      averageTicket: String(selectedProvider.averageTicket),
      goal: String(selectedProvider.goal)
    }
  });

  const [isLoading, setIsLoading] = useState(false)

  const averageTicket = watch("averageTicket");
  const goal = watch("goal");

  const handleOnSuccess = async (data: ConfigModalData) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const configs: ProviderConfigs = {
        averageTicket: unMaskMoneyToNumber(data.averageTicket),
        goal: Number(data.goal)
      }

      await updateProviderConfigs(configs)

      await delay(100)

      handleUpdateSelectedProviderConfigs(configs)

      queryClient.invalidateQueries({ queryKey: [PROVIDER_CLIENTS_QUERY_KEY] })
      await queryClient.invalidateQueries({ queryKey: [DASHBOARD_KEY] })


      toast.success(t("providers.edit.update_success"))

      handleClose()
    } catch (error) {
      console.error(error)
      toast.error(t("login.general_error"))
    } finally {
      setIsLoading(false)
    }
  }

  return createPortal(
    <div>
      <div
        className="modalBackground"
        onClick={handleClose}
      />
      <form
        onSubmit={handleSubmit(handleOnSuccess)}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md py-8 px-12 rounded-xl w-96 z-50 bg-modal flex flex-col gap-4"
      >
        <p>Configurações</p>
        <div className="flex flex-col gap-1">
          <MoneyTextField
            label={t("providers.form.average_ticket")}
            placeholder={t("providers.form.average_ticket_placeholder")}
            type="text"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error={errors.averageTicket?.message ? t(errors.averageTicket.message as any) : undefined}
            value={averageTicket}
            onChange={(value) => {
              setValue("averageTicket", value);
            }}
            variant="secondary"
          />
          <MaskedTextField
            label={t("providers.form.goal")}
            placeholder={t("providers.form.goal_placeholder")}
            type="text"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error={errors.goal?.message ? t(errors.goal.message as any) : undefined}
            mask={Masks.NUMBER}
            value={goal}
            onChange={(value) => {
              setValue("goal", value);
            }}
            variant="secondary"
          />
        </div>
        <Button
          className="mt-4"
          type="submit"
          loading={isLoading}
        >
          Atualizar
        </Button>
      </form>
    </div>,
    document.body
  )
}
