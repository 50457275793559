import { Button } from "components/Button"
import { TextField } from "components/Forms/TextField"
import { useViewModel } from "./viewModel"
import { ChatIntegration } from "models/chatIntegration"
import { CopyText } from "components/Forms/CopyText"
import { MultiSelect } from "components/Forms/MultiSelect"
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel"
import { Select } from "components/Forms/Select"

type Props = {
  providerId: string
  selectedIntegration: ChatIntegration | undefined
  onSave: SaveOrUpdateChatIntegrationFunction;
  onClose: () => void
}

export const HuggyForm = ({ providerId, selectedIntegration, onSave, onClose }: Props) => {
  
  const viewModel = useViewModel(selectedIntegration, providerId, onSave)

  if (viewModel.step === 1) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
        className="flex flex-col gap-5 overflow-y-auto max-h-[calc(100svh-384px)]"
      >
        {viewModel.selectedSellersOptions.map((seller, index) => (
          <div key={seller.id}>
            <Select
              label={`Associe ${seller.name}`}
              onChange={(value) => {
                // Check if the internal seller is already select on the list
                const relatedSeller = viewModel.selectedSellersRelated.find(
                  (relatedSeller) => relatedSeller.seller === value
                );

                // Get current external seller
                const seller = viewModel.selectedSellersOptions[index];

                // Check if the external seller is already select on the list
                const sellerOnTheList = viewModel.selectedSellersRelated.find(
                  (relatedSeller) => relatedSeller.externalSeller === seller.id
                );

                // If external seller is selected on the list, change the internal seller related to the new selection
                if (sellerOnTheList) {
                  viewModel.setValue("huggy.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) =>
                        relatedSeller.externalSeller !== seller.id
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.id,
                    },
                  ]);
                  return;
                }

                // If the internal seller selected is on the list, change they to the current external seller
                if (relatedSeller) {
                  viewModel.setValue("huggy.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) => relatedSeller.seller !== value!
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.id,
                    },
                  ]);
                  return;
                }

                // If the internal and external seller isn't selected push it
                viewModel.setValue("huggy.sellersRelated", [
                  ...viewModel.selectedSellersRelated,
                  {
                    seller: value!,
                    externalSeller: seller.id,
                  },
                ]);
              }}
              options={viewModel.internalSellers.map((internalSeller) => ({
                value: internalSeller.id,
                label: internalSeller.name,
              }))}
              selected={
                viewModel.selectedSellersRelated.find(
                  (sellerRelated) => sellerRelated.externalSeller === seller.id
                )?.seller
              }
              error={viewModel.errors.type?.message}
              variant="secondary"
            />
          </div>
        ))}
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button
            loading={viewModel.loadingForm}
            theme="highlight"
            className="px-7"
          >
            Salvar
          </Button>
        </div>
      </form>
    )
  }

  return (
    <form 
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
      className="flex flex-col gap-5"
    >
      <TextField
        label='Id do cliente do app'
        placeholder='Digite o ID do cliente'
        error={viewModel.errors.huggy?.clientId?.message}
        register={viewModel.register("huggy.clientId", {
          required: { value: true, message: "O ID do cliente é obrigatório" }
        })}
        disabled={viewModel.status === 'old'}
        variant="secondary"
      />
      <CopyText
        text={import.meta.env.VITE_HOST_URL + '-' + providerId}
        label="URL de redirecionamento"
        variant="secondary"
      />
      {viewModel.departments.length > 0 && (
        <>
          <MultiSelect
            label="Departamento"
            options={viewModel.departments.map(department => ({
              label: department.name,
              value: department._id
            }))}
            selected={viewModel.huggyDepartments}
            onChange={(value) => viewModel.setValue("huggy.departments", value)}
            error={viewModel.errors.huggy?.departments?.message}
            variant="secondary"
          />
          <MultiSelect
            label="Selecione os vendedores"
            onChange={(value) => viewModel.setValue("huggy.sellers", value)}
            options={viewModel.sellers.map((seller) => ({
              value: seller.id,
              label: seller.name,
            }))}
            selected={viewModel.selectedSellers}
            error={viewModel.errors.huggy?.sellers?.message}
            variant="secondary"
          />
        </>
      )}
      <div className="flex justify-between items-center">
        {selectedIntegration?.huggy?.token && <Button onClick={viewModel.handleRefreshToken} type="button">Atualizar token</Button>}
        <Button loading={viewModel.loadingForm} theme="highlight" className="self-end">
          Continuar
        </Button>
      </div>
    </form>
  )
}