import {
  APILeadCard,
  ILeadChannel,
  ILeadContantType,
  ILeadDocumentType,
  ILeadSocialOrigin,
  Lead,
  LeadCard,
} from "models/lead";
import { Pagination, PaginationV2 } from "utils/pagination";
import { api } from "./api";
import { BoardColumn, BoardTag } from "models/board";
import { LeadPeriodFilter } from "models/filter";
import { APISeller, Seller, parseAPISeller } from "models/seller";

// CRUD

type LeadResponse = Omit<Lead, "id"> & {
  _id: string;
};

export const getLead = async (id: string): Promise<Lead> => {
  const { data } = await api.get<LeadResponse>(`/v1/leads/${id}`);
  return {
    ...data,
    id: data._id,
  };
};

export const getLeads = async (
  page: number,
  perPage: number,
  initDate: Date,
  endDate: Date
): Promise<Pagination<Lead>> => {
  const query = new URLSearchParams();
  query.append("page", String(page));
  query.append("perPage", String(perPage));
  query.append("initDate", initDate.toISOString());
  query.append("endDate", endDate.toISOString());

  const { data } = await api.get<Pagination<LeadResponse>>(
    `/v1/leads?${query.toString()}`
  );
  return {
    ...data,
    data: data.data.map((lead) => {
      return {
        ...lead,
        id: lead._id,
      };
    }),
  };
};

export const changeLeadStatus = async (
  id: string,
  status: string
): Promise<Lead> => {
  const { data } = await api.patch<LeadResponse>(`/v1/leads/${id}/status`, {
    status,
  });
  return {
    ...data,
    id: data._id,
  };
};

type LeadUpdateForm = {
  name: string;
  socialOrigin: ILeadSocialOrigin;
  seller?: string;
  selledAt?: string;
  description: string;
};

type LeadUpdateResponse = {
  seller: string | null;
};

export const updateLeadData = async (
  id: string,
  lead: LeadUpdateForm
): Promise<LeadUpdateResponse> => {
  const { data } = await api.put<LeadUpdateResponse>(`/v1/leads/${id}`, lead);
  return data;
};

export const updateLeadDescription = async (
  id: string,
  description: string
): Promise<void> => {
  await api.put<void>(`/v1/leads/${id}/description`, {
    description,
  });
};

type LeadCreateForm = {
  name: string;
  channel: ILeadChannel;
  date: string;
  contact: string;
  contactType: ILeadContantType;
  document: string;
  documentType: ILeadDocumentType;
  phone: string;
  socialOrigin: ILeadSocialOrigin;
  status: string;
  seller: string;
  selledAt: string | null;
  description: string;
  allowDuplicated: boolean;
};

export const createLead = async (lead: LeadCreateForm): Promise<void> => {
  await api.post("/v1/leads", lead);
};

type APILead = Omit<Lead, "id" | "date" | "seller"> & {
  _id: string;
  date: string;
  seller: APISeller | null;
};

export const searchLeadByPhone = async (
  phone: string,
  page: number
): Promise<PaginationV2<Lead>> => {
  const { data } = await api.get<PaginationV2<APILead>>(
    `/v1/leads/search?phone=${phone}&page=${page}`
  );
  return {
    ...data,
    data: data.data.map((lead) => {
      return {
        ...lead,
        id: lead._id,
        date: new Date(lead.date),
        seller: lead.seller ? parseAPISeller(lead.seller) : null,
      };
    }),
  };
};

// Board

type LeadsBoardResponse = {
  columns: BoardColumn<APILeadCard>[];
  sellers: APISeller[];
};

type LeadsBoard = {
  columns: BoardColumn<LeadCard>[];
  sellers: Seller[];
};

type LeadBoardParams = {
  perPage: number;
  initDate?: string;
  endDate?: string;
  signal?: AbortSignal;
  origins?: string[];
  leadPeriodFilter: LeadPeriodFilter;
  onlySelled?: boolean;
  seller: string | undefined;
};

export const getLeadsBoardAPI = async ({
  perPage,
  initDate,
  endDate,
  signal,
  origins,
  leadPeriodFilter,
  seller,
}: LeadBoardParams): Promise<LeadsBoard> => {
  const params = new URLSearchParams();

  params.append("perPage", String(perPage));
  if (initDate) {
    params.append("initDate", initDate);
  }
  if (endDate) {
    params.append("endDate", endDate);
  }
  if (origins) {
    params.append("origins", origins.join(","));
  }

  if (leadPeriodFilter === "selled") {
    params.append("onlySelled", String(true));
  } else {
    params.append("leadPeriodFilter", leadPeriodFilter);
  }

  if (seller) {
    params.append("seller", seller);
  }

  const { data } = await api.get<LeadsBoardResponse>(
    `/v1/leads-board?${params.toString()}`,
    { signal }
  );
  return {
    sellers: data.sellers.map(parseAPISeller),
    columns: data.columns.map((column) => {
      return {
        ...column,
        state: "success",
        items: column.items.map((lead) => {
          return {
            ...lead,
            id: lead._id,
            date: new Date(lead.date),
            seller: lead.seller ? parseAPISeller(lead.seller) : null,
          };
        }),
      };
    }),
  };
};

type LeadsBoardLoadMoreColumnAPI = {
  data: APILeadCard[];
  hasMore: boolean;
};

type LeadsBoardLoadMoreColumn = {
  items: LeadCard[];
  hasMore: boolean;
};

type LeadBoardQueryMoreParams = {
  perPage: number;
  columnId: string;
  lastPosition: number;
  initDate?: string;
  endDate?: string;
  leadPeriodFilter: LeadPeriodFilter;
  origins?: string[];
  onlySelled?: boolean;
  seller?: string;
};

export const getLeadsBoardLoadMoreAPI = async ({
  perPage,
  columnId,
  lastPosition,
  initDate,
  endDate,
  leadPeriodFilter,
  origins,
  seller,
}: LeadBoardQueryMoreParams): Promise<LeadsBoardLoadMoreColumn> => {
  const params = new URLSearchParams();

  params.append("perPage", String(perPage));
  params.append("lastPosition", String(lastPosition));
  if (initDate) {
    params.append("initDate", initDate);
  }
  if (endDate) {
    params.append("endDate", endDate);
  }
  if (origins) {
    params.append("origins", origins.join(","));
  }

  if (leadPeriodFilter === "selled") {
    params.append("onlySelled", String(true));
  } else {
    params.append("leadPeriodFilter", leadPeriodFilter);
  }

  if (seller) {
    params.append("seller", seller);
  }

  const { data } = await api.get<LeadsBoardLoadMoreColumnAPI>(
    `/v1/leads-board/column/${columnId}?${params.toString()}`
  );
  return {
    ...data,
    items: data.data.map((lead) => {
      return {
        ...lead,
        id: lead._id,
        date: new Date(lead.date),
        seller: lead.seller ? parseAPISeller(lead.seller) : null,
      };
    }),
  };
};

type MoveCardAPIResponse = {
  seller: string | null;
};

export const boardCardMoveAPI = async (
  id: string,
  column: string,
  position: number
): Promise<MoveCardAPIResponse> => {
  const { data } = await api.patch<MoveCardAPIResponse>(
    `/v1/leads-board/card/${id}/move`,
    { column, position }
  );
  return data;
};

export const boardAddColumnAPI = async (
  id: string,
  title: string,
  tag?: BoardTag,
  index?: number
) => {
  await api.post<void>(`/v1/leads-board/column/${id}`, { title, tag, index });
};

export const boardRemoveColumnAPI = async (id: string) => {
  return await api.delete<void>(`/v1/leads-board/column/${id}`);
};

export const boardEditColumnAPI = async (
  id: string,
  title: string,
  tag?: BoardTag
) => {
  await api.put<void>(`/v1/leads-board/column/${id}`, { title, tag });
};

// Dashboard

type LeadsCountResponse = {
  currentPeriod: number;
  lastPeriod: number;
};

export const getLeadsCount = async (
  initDate: Date,
  endDate: Date
): Promise<LeadsCountResponse> => {
  const query = new URLSearchParams();
  query.append("initDate", initDate.toISOString());
  query.append("endDate", endDate.toISOString());

  const { data } = await api.get<LeadsCountResponse>(
    `/v1/leads/count?${query.toString()}`
  );
  return data;
};
