export const getCurrencySymbol = (currency: string, language: string) => {
  return Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d/g, "")
    .trim();
};

export const toCurrency = (
  value: number,
  language: string,
  currency: string
) => {
  return Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
    currencyDisplay: "code",
  })
    .format(value)
    .replace(currency, "");
};

export const toPercent = (value: number, language: string) => {
  return Intl.NumberFormat(language, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const toDecimal = (
  value: number,
  language: string,
  currency: string
) => {
  return Intl.NumberFormat(language, {
    style: "decimal",
    maximumFractionDigits: 2,
    currency: currency,
  }).format(value);
};
