import { TFunction } from "i18next";

export type BoardColumn<T> = {
  id: string;
  title: string;
  items: T[];
  hasMore: boolean;
  state: "loading" | "success" | "error";
  nbItems: number;
  tag?: BoardTag;
};

export type BoardColumnQuery = {
  id: string;
  name: string;
  tag: BoardTag;
};

export type BoardTag = {
  value: BoardTagValue;
  label: string;
};

type BoardTagValue =
  | "pending"
  | "waiting_cep"
  | "out_area"
  | "already_client"
  | "finance"
  | "negotiation"
  | "stopped_responding"
  | "lost_lead"
  | "scheduled"
  | "sold"
  | "gave_up";

export const tagList = (t: TFunction<"translation", undefined>): BoardTag[] =>
  t("leads.board.tags", { returnObjects: true });

export const tagColumnColor: Record<BoardTagValue | "unkown", string> = {
  pending: "bg-active",
  waiting_cep: "bg-paused",
  out_area: "bg-purple",
  already_client: "bg-purple",
  finance: "bg-purple",
  negotiation: "bg-paused",
  stopped_responding: "bg-paused",
  lost_lead: "bg-purple",
  scheduled: "bg-purple",
  sold: "bg-purple",
  gave_up: "bg-purple",
  unkown: "bg-tertiary",
};

export const cardColumnColor: Record<BoardTagValue | "unkown", string> = {
  pending: "bg-secondary",
  waiting_cep: "bg-paused",
  out_area: "bg-inactive",
  already_client: "bg-purple",
  finance: "bg-purple",
  negotiation: "bg-paused",
  stopped_responding: "bg-inactive",
  lost_lead: "bg-inactive",
  scheduled: "bg-active",
  sold: "bg-active",
  gave_up: "bg-inactive",
  unkown: "bg-tertiary",
};

export const sellTags = ["scheduled", "sold", "gave_up"];
export const outOfAreaTags = ["out_area"];
