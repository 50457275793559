import { Listbox, Transition } from "@headlessui/react";

import { Label } from "../Label";

import { Fragment, useMemo } from "react";
import { cn } from "utils/styles";
import { useTranslation } from "react-i18next";

export type SelectItem = {
  label: string;
  value: string;
};

type StatusSelectProps = {
  id?: string;
  label?: string;
  selected: string;
  disabled?: boolean;
  options: SelectItem[];
  onChange?: (value: string) => void;
  placeholder?: string;
};

function StatusSelect({
  label,
  selected,
  disabled,
  options,
  onChange,
}: StatusSelectProps) {
  const { t, i18n } = useTranslation();
  const selectedOption = useMemo(() => {
    return options.find((item) => item.value === selected)?.label ?? "";
  }, [selected, i18n.language]);

  return (
    <div className="w-full flex flex-col">
      {label && <Label className="px-5 mb-3">{label}</Label>}
      <Listbox value={selected} onChange={onChange} disabled={disabled}>
        <div className="relative">
          <Listbox.Button
            className={cn(
              "w-full border-none outline-none py-2.5 pl-8 pr-8 text-sm  text-white rounded-full text-center",
              selectedOption === t("providers.status.active")
                ? "bg-active"
                : "",
              selectedOption === t("providers.status.inactive")
                ? "bg-inactive"
                : "",
              selectedOption === t("providers.status.paused") ? "bg-paused" : ""
            )}
          >
            {selectedOption}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto bg-primaryMultiply/90 text-base shadow-xl focus:outline-none sm:text-sm rounded-2xl">
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={({ active }) =>
                    cn(
                      "relative select-none text-white text-center",
                      active ? "bg-primary/40 rounded-xl" : ""
                    )
                  }
                  value={option.value}
                >
                  {({ selected }) => (
                    <span
                      className={`block truncate cursor-pointer py-2 px-4 ${
                        selected ? "bg-primary/40 rounded-xl" : ""
                      }`}
                    >
                      {option.label}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export { StatusSelect };
