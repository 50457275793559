import { useQuery } from "react-query";

import {
  getDashboardLeadsData,
} from "services/dashboardService";
import useAuth from "hooks/useAuth";
import { useFilters } from "hooks/useFilters";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const DASHBOARD_KEY = "dashboard";

type TAB = "leads" | "sales";

export const useViewmodel = () => {
  const { user, selectedProvider, selectedSeller } = useAuth();
  const { selectedDate } = useFilters();
  const { t } = useTranslation();

  const [tab, setTab] = useState<TAB>("leads");

  const range = `${selectedDate?.initDate.toDateString()}-${selectedDate?.endDate.toDateString()}`;

  const dashboardData = useQuery(
    [DASHBOARD_KEY, user?._id, range, selectedProvider.id, selectedSeller],
    () =>
      getDashboardLeadsData(
        selectedDate!.initDate,
        selectedDate!.endDate,
        selectedSeller
      ),
    { enabled: selectedProvider.id !== "admin" }
  );
  dashboardData.isLoading =
    dashboardData.isLoading || (dashboardData.isFetching && !dashboardData.data) || dashboardData.isRefetching;

  const refetch = () => {
    dashboardData.refetch();
  };

  return {
    dashboardData: dashboardData.data,
    isLoading: dashboardData.isLoading,
    hasError: dashboardData.isError,
    refetch,
    hasProvider: selectedProvider.id !== "admin",
    tab,
    setTab,
    t,
  };
};
