import { Button } from "components/Button";
import { DotLoading } from "components/DotLoading";
import { Table } from "components/Tables/Table";
import { Pagination } from "components/Tables/Pagination";

import { statusToText } from "models/provider";

import { useViewModel } from "./viewModel";

import { InfoCard } from "components/InfoCard";
import { PageHeader } from "components/Page/PageHeader";
import { PageFilter } from "components/Page/PageFilter";
import { cn } from "utils/styles";

export default function Campaigns() {
  const {
    isLoadingPage,
    isErrorPage,
    retryPage,
    navigate,
    campaigns,
    campaignsPage,
    handleChangePage,
    infoCards,
    t,
  } = useViewModel();

  const header = (
    <div className="flex justify-between items-center">
      <PageHeader title={t("campaign.title")} />
      <PageFilter />
    </div>
  );

  if (isLoadingPage) {
    return (
      <div>
        {header}
        <div className="flex flex-col mt-7 items-center text-xl justify-center h-[calc(70svh)] gap-5 text-center">
          <p>{t("campaign.loading")}</p>
          <DotLoading />
        </div>
      </div>
    );
  }

  if (isErrorPage) {
    return (
      <div>
        {header}
        <div className="flex flex-col mt-7 items-center text-xl justify-center h-[calc(70svh)] gap-5 text-center">
          <p>{t("campaign.error")}</p>
          <Button onClick={retryPage}>{t("actions.retry")}</Button>
        </div>
      </div>
    );
  }

  if (
    campaigns?.campaigns.data.length === 0 &&
    campaigns.campaigns.numberOfPages === 1
  ) {
    return (
      <div>
        {header}
        <div className="flex flex-col mt-7 items-center text-xl justify-center h-[calc(70svh)] gap-5 text-center">
          <p>{t("campaign.empty")}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {header}
      <div className="mt-7 desktop:mt-14 mb-7 desktop:mb-16 grid grid-cols-1 desktop:grid-cols-3 gap-2 desktop:gap-5 bg-primary/40 py-4 rounded-2xl px-4 desktop:px-12">
        {infoCards.map((card) => (
          <InfoCard key={card.type} type={card.type} value={card.value} />
        ))}
      </div>
      <div>
        <Table
          variant="secondary"
          className={cn("!h-[calc(39vh)] desktop:!h-[calc(49vh)]")}
          headers={t("campaign.table.headers", { returnObjects: true })}
          fields={[
            { key: "name" },
            { key: "leads", type: "number" },
            { key: "visits", type: "number" },
            { key: "leadCost", type: "money" },
            { key: "invested", type: "money" },
            { key: "status", type: "status" },
          ]}
          data={campaigns?.campaigns?.data}
          transforms={[
            {
              key: "status",
              transform: (campaign) => statusToText(campaign.status, t),
            },
          ]}
          overrides={[
            {
              key: "name",
              override: (index) => {
                const campaign = campaigns!.campaigns.data[index];
                return (
                  <>
                    <img
                      src={`/icons/campaigns/${campaign.type}.svg`}
                      alt={campaign.name}
                    />
                    <span>{campaign.name}</span>
                  </>
                );
              },
            },
          ]}
          actions={(campaign) => {
            if (campaign.type === "facebook") {
              return (
                <>
                  <button onClick={() => navigate(`/campanhas/${campaign.id}`)}>
                    {t("actions.view")}
                  </button>
                </>
              );
            }
            return null;
          }}
        />
        <Pagination
          currentPage={campaignsPage}
          numberOfPages={campaigns?.campaigns.numberOfPages ?? 0}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  );
}
