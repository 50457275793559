import { Button } from "components/Button";
import { TextField } from "components/Forms/TextField";
import { useViewModel } from "./viewModel";
import { ChatIntegration } from "models/chatIntegration";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { MaskedTextField } from "components/Forms/MaskedTextField";
import { Masks } from "utils/mask";
import { Select } from "components/Forms/Select";
import { midiaSocialOrigins } from "models/lead";
import { Label } from "components/Forms/Label";

type Props = {
  selectedIntegration: ChatIntegration | undefined;
  onClose: () => void;
  onSave: SaveOrUpdateChatIntegrationFunction;
};

export const WhatsappForm = ({
  selectedIntegration,
  onClose,
  onSave,
}: Props) => {
  const viewModel = useViewModel(selectedIntegration, onSave);

  return (
    <form
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitSuccess)}
      className="flex flex-col gap-5"
    >
      <MaskedTextField
        label="Número do WhatsApp"
        placeholder="(00) 90000-0000"
        type="phone"
        error={viewModel.errors.name?.message}
        mask={Masks.PHONE}
        value={viewModel.whatsapp}
        onChange={(value) => {
          viewModel.setValue("name", value);
        }}
        variant="secondary"
      />
      <Label>Detecção de origem social</Label>
      {viewModel.errors.whatsappUnofficial?.socialMatching && (
        <p className="text-red-500 text-xs leading-4">
          {viewModel.errors.whatsappUnofficial?.socialMatching.message}
        </p>
      )}
      <div className="flex flex-col gap-2 h-56 overflow-y-auto">
        {viewModel.socialMatchingFields.map((field, index) => (
          <div
            key={field.id}
            className="flex flex-col gap-2 items-center relative"
          >
            <Select
              label="Rede social"
              variant="secondary"
              selected={field.socialOrigin as string}
              onChange={(value) =>
                viewModel.handleUpdateSocialMatching(index, value!)
              }
              options={midiaSocialOrigins(viewModel.t)}
            />
            <TextField
              label="Palavras chave"
              variant="secondary"
              register={viewModel.register(
                `whatsappUnofficial.socialMatching.${index}.keywords`
              )}
              helpText="Separe as palavras chave por vírgula"
              error={
                viewModel.errors.whatsappUnofficial?.socialMatching?.[index]
                  ?.keywords?.message
              }
            />
            <Button
              theme="default"
              onClick={() => viewModel.handleRemoveSocialMatching(index)}
              className="absolute right-0 top-0 h-6 w-6 leading-8"
            >
              x
            </Button>
          </div>
        ))}
        <Button
          variant="outline"
          className="self-start my-2"
          onClick={viewModel.handleAppendSocialMatching}
        >
          Adicionar novo detector
        </Button>
      </div>
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={viewModel.loadingForm} theme="highlight">
          Salvar
        </Button>
      </div>
    </form>
  );
};
