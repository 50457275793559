import { BoardColumn } from "models/board";
import { LeadCard } from "models/lead";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  boardAddColumnAPI,
  boardCardMoveAPI,
  boardEditColumnAPI,
  boardRemoveColumnAPI,
} from "services/leadService";
import { queryClient } from "services/queryClient";
import { IState } from "store";
import { updateSelectedLead } from "store/slices/boardFilters";
import { moveCard } from "./utils";
import { Log } from "utils/log";
import { delay } from "utils/utils";
import { toMs } from "utils/date";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

type SelectedColumn = {
  column: BoardColumn<LeadCard> | undefined;
  beforeIndex: number | undefined;
};

type Props = {
  leadsColumns: BoardColumn<LeadCard>[];
  originsFilter: string[];
  setLeadsColumns: (columns: BoardColumn<LeadCard>[]) => void;
  refetch: () => void;
};

export const useBoardCrudViewModel = ({
  leadsColumns,
  setLeadsColumns,
  refetch,
  originsFilter,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const editLead = useSelector(
    (state: IState) => state.boardFilters.selectedLead
  );
  const [isAddingOrEditingColumn, setIsAddingOrEditingColumn] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<SelectedColumn | null>(
    null
  );

  const handleAddNewColumn = () => handleShowAddOrEditColumnDialog(null);
  const handleAddNewColumnBefore = (index: number) => () =>
    handleShowAddOrEditColumnDialog(null, index);

  const handleShowAddOrEditColumnDialog = (
    column: BoardColumn<LeadCard> | null,
    beforeIndex?: number
  ) => {
    if (column === null) {
      setSelectedColumn({
        column: undefined,
        beforeIndex,
      });
    } else {
      setSelectedColumn({
        column,
        beforeIndex: beforeIndex ?? undefined,
      });
    }
    setIsAddingOrEditingColumn(true);
  };

  const handleCloseAddOrEditColumnDialog = () => {
    setIsAddingOrEditingColumn(false);
    setSelectedColumn(null);
  };

  const handleAddOrUpdateColumn = async (
    column: BoardColumn<LeadCard>,
    beforeColumnIndex?: number
  ) => {
    const index = leadsColumns.findIndex((item) => item.id === column.id);

    let newColumns: BoardColumn<LeadCard>[];

    if (index === -1) {
      if (beforeColumnIndex !== undefined) {
        const previousColumns = leadsColumns.slice(0, beforeColumnIndex);
        const nextColumns = leadsColumns.slice(beforeColumnIndex);
        newColumns = [...previousColumns, column, ...nextColumns];
      } else {
        newColumns = [...leadsColumns, column];
      }

      boardAddColumnAPI(
        column.id,
        column.title,
        column.tag,
        beforeColumnIndex
      ).catch((error) => {
        Log.error("Error on add column", error);
        toast.error(t("leads.board.errors.add_column", { name: column.title }));
        refetch();
      });
    } else {
      newColumns = [...leadsColumns];
      newColumns[index] = column;

      boardEditColumnAPI(
        leadsColumns[index].id,
        column.title,
        column.tag
      ).catch((error) => {
        Log.error("Error on edit column", error);
        toast.error(
          t("leads.board.errors.edit_column", { name: column.title })
        );
        refetch();
      });
    }

    setLeadsColumns(newColumns);
    handleCloseAddOrEditColumnDialog();
  };

  const handleDeleteColumn = (index: number) => () => {
    const column = leadsColumns[index];

    if (column.items.length > 0) {
      toast.error(
        t("leads.board.errors.remove_column", { name: column.title })
      );
      return;
    }

    const newColumns = [...leadsColumns];
    newColumns.splice(index, 1);
    setLeadsColumns(newColumns);

    boardRemoveColumnAPI(column.id).catch((error) => {
      Log.error("Error on remove column", error);
      t("leads.board.errors.remove_column", { name: column.title });
      refetch();
    });
  };

  const handleOpenEditLead = (lead: LeadCard) => {
    dispatch(
      updateSelectedLead({
        ...lead,
        date: lead.date.toISOString(),
      })
    );
  };

  const handleCloseEditLead = () => {
    dispatch(updateSelectedLead(null));
  };

  const handleConfirmEditLead = async (lead: LeadCard, columnId: string) => {
    const updateLead = async () => {
      const findColumn = leadsColumns.findIndex(
        (column) => column.id === columnId
      );
      const column = leadsColumns[findColumn];

      if (findColumn === -1 || !column) {
        refetch();
        return;
      }

      if (originsFilter.length > 0 && !originsFilter.includes(lead.origin)) {
        const columns = [...leadsColumns];
        columns[findColumn] = {
          ...column,
          items: column.items.filter((item) => item.id !== lead.id),
        };
        setLeadsColumns(columns);
        toast.success(
          t("leads.board.messages.update_success", { name: lead.name })
        );
        return;
      }

      if (lead.status !== column.id) {
        const newColumnIndex = leadsColumns.findIndex(
          (item) => item.id === lead.status
        );
        const newColumn = leadsColumns[newColumnIndex];

        const lastlead = newColumn.items.at(newColumn.items.length - 1);

        const newPosition = moveCard(lastlead?.position);

        const updatedColumns = [...leadsColumns];
        updatedColumns[findColumn] = {
          ...column,
          items: column.items.filter((item) => item.id !== lead.id),
        };
        updatedColumns[newColumnIndex] = {
          ...newColumn,
          items: [
            ...newColumn.items,
            {
              ...lead,
              position: newPosition,
            },
          ],
        };

        try {
          await delay(toMs({ seconds: 1 }));
          await boardCardMoveAPI(lead.id, lead.status, newPosition);

          setLeadsColumns(updatedColumns);
          toast.success(
            t("leads.board.messages.update_success", { name: lead.name })
          );
        } catch (error) {
          const _error = error as AxiosError;

          if (_error.isAxiosError) {
            const { status } = _error.response!;

            if (status === 403) {
              toast.error(
                t("leads.board.errors.already_attributed_seller", {
                  name: lead.name,
                })
              );
              Log.error("Error on move card", _error);
              refetch();
              return;
            }
          }

          Log.error("Error on move card", error as Error);
          toast.error(
            t("leads.board.errors.failed_to_move", {
              name: lead.name,
            })
          );
          refetch();
        }
        return;
      }

      const findLead = column?.items.findIndex((item) => item.id === lead.id);

      column.items[findLead] = lead;

      const columns = [...leadsColumns];
      columns[findColumn] = column;

      setLeadsColumns(columns);
      toast.success(
        t("leads.board.messages.update_success", { name: lead.name })
      );
    };

    await updateLead();

    queryClient.invalidateQueries("leads");
    handleCloseEditLead();
  };

  return {
    editLead,
    selectedColumn,
    isAddingOrEditingColumn,
    handleAddNewColumn,
    handleAddNewColumnBefore,
    handleShowAddOrEditColumnDialog,
    handleCloseAddOrEditColumnDialog,
    handleAddOrUpdateColumn,
    handleDeleteColumn,
    handleOpenEditLead,
    handleCloseEditLead,
    handleConfirmEditLead,
  };
};
